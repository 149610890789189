/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AggregateDescriptor {
	aggregates?: AggregateFunction[] | null;
	member?: string | null;
}

export interface AggregateFunction {
	aggregateMethodName?: string | null;
	caption?: string | null;
	functionName?: string | null;
	memberType: Type;
	resultFormatString?: string | null;
	sourceField?: string | null;
}

export interface Assembly {
	/** @deprecated */
	codeBase?: string | null;
	customAttributes: CustomAttributeData[];
	definedTypes: TypeInfo[];
	entryPoint: MethodInfo;
	/** @deprecated */
	escapedCodeBase: string;
	exportedTypes: Type[];
	fullName?: string | null;
	/** @deprecated */
	globalAssemblyCache: boolean;
	/** @format int64 */
	hostContext: number;
	imageRuntimeVersion: string;
	isCollectible: boolean;
	isDynamic: boolean;
	isFullyTrusted: boolean;
	location: string;
	manifestModule: Module;
	modules: Module[];
	reflectionOnly: boolean;
	securityRuleSet: SecurityRuleSet;
}

export interface AttachmentCreateParams {
	/** @format int32 */
	entityId: number;
	isPod?: boolean;
	usage?: AttachmentUsageType;
}

export interface AttachmentCreatePayload {
	files?: File[];
	invoiceFiles?: File[];
}

export interface AttachmentListDetailParams {
	/** @format int32 */
	entityId: number;
	isInvoice?: boolean;
	isPod?: boolean;
	usage?: AttachmentUsageType;
}

/** @format int32 */
export type AttachmentUsageType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface BffLegNoteDataCreateParams {
	/** @format int32 */
	legId?: number;
}

export type BffSaveMultiEditCreatePayload = MultiEdit[];

export interface BulkCreateCustomerRequest {
	accountCode: string;
	address?: string | null;
	/** @format int32 */
	checklistId?: number | null;
	/** @format int32 */
	currencyId: number;
	emails?: string[] | null;
	includePodAttachmentToInvoiceEmail: boolean;
	invoiceFrequency: InvoiceFrequencyType;
	invoiceSentMethod: InvoiceSentMethodType;
	invoiceTemplate?: string | null;
	invoiceType: InvoiceType;
	isEuCustomer: boolean;
	isPurchaseOrderNumberMandatory: boolean;
	mergeAttachmentInOneFile: boolean;
	name: string;
	phones?: string[] | null;
	status: CustomerStatusType;
	vatNumber?: string | null;
}

/** @format int32 */
export type CallingConventions = 1 | 2 | 3 | 32 | 64;

export interface ChecklistListParams {
	request?: DataSourceRequest;
}

export interface ClientHandlerDescriptor {
	handlerName?: string | null;
	templateDelegate: ObjectObjectFunc;
}

export interface ClosestDriversRecommendation {
	/** @format double */
	distance: number;
	/** @format int32 */
	driverId: number;
	driverName: string;
	/** @format date-time */
	latestKnownLocationDate: string;
	/** @format double */
	latitude: number;
	/** @format double */
	longitude: number;
	/** @format int32 */
	trailerId: number | null;
	trailerName: string | null;
	/** @format int32 */
	truckId: number | null;
	truckName: string | null;
}

export interface ConstraintListParams {
	request?: DataSourceRequest;
}

export interface ConstructorInfo {
	attributes: MethodAttributes;
	callingConvention: CallingConventions;
	containsGenericParameters: boolean;
	customAttributes: CustomAttributeData[];
	declaringType: Type;
	isAbstract: boolean;
	isAssembly: boolean;
	isCollectible: boolean;
	isConstructedGenericMethod: boolean;
	isConstructor: boolean;
	isFamily: boolean;
	isFamilyAndAssembly: boolean;
	isFamilyOrAssembly: boolean;
	isFinal: boolean;
	isGenericMethod: boolean;
	isGenericMethodDefinition: boolean;
	isHideBySig: boolean;
	isPrivate: boolean;
	isPublic: boolean;
	isSecurityCritical: boolean;
	isSecuritySafeCritical: boolean;
	isSecurityTransparent: boolean;
	isSpecialName: boolean;
	isStatic: boolean;
	isVirtual: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	methodHandle: RuntimeMethodHandle;
	methodImplementationFlags: MethodImplAttributes;
	module: Module;
	name: string;
	reflectedType: Type;
}

export interface CreateChecklistRequest {
	description?: string | null;
	items: string[];
	name: string;
}

export interface CreateConstraintRequest {
	name: string;
}

export interface CreateCurrencyExchangeRateRequest {
	/** @format double */
	exchangeRate: number;
	/** @format int32 */
	fromCurrencyId: number;
	/** @format int32 */
	toCurrencyId: number;
}

export interface CreateCurrencyRequest {
	code: string;
}

export interface CreateCustomerRequest {
	accountCode: string;
	address?: string | null;
	/** @format int32 */
	checklistId?: number | null;
	/** @format int32 */
	currencyId: number;
	emails?: string[] | null;
	includePodAttachmentToInvoiceEmail: boolean;
	invoiceFrequency: InvoiceFrequencyType;
	invoiceSentMethod: InvoiceSentMethodType;
	invoiceTemplate?: string | null;
	invoiceType: InvoiceType;
	isEuCustomer: boolean;
	isPurchaseOrderNumberMandatory: boolean;
	mergeAttachmentInOneFile: boolean;
	name: string;
	phones?: string[] | null;
	vatNumber?: string | null;
}

export interface CreateDriverRequest {
	emails: string[];
	firstName: string;
	lastName: string;
	/** @format date-time */
	licenceExpirationDate: string;
	notes?: string | null;
	phones: string[];
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	truckId?: number | null;
}

export interface CreateGoodsForLoadRequest {
	goods: GoodsInformationRequest[];
}

export interface CreateGoodsRequest {
	/** @format date-time */
	collectionDate: string;
	/** @format int32 */
	collectionLocationId?: number | null;
	constraintIds?: number[] | null;
	/** @format int32 */
	currencyId: number;
	/** @format date-time */
	deliveryDate: string;
	/** @format int32 */
	deliveryLocationId?: number | null;
	dimensions?: string | null;
	isFreeOfCharge: boolean;
	/** @format int32 */
	jobId: number;
	name: string;
	notes?: string | null;
	/** @format int32 */
	quantity: number;
	/** @format double */
	revenue: number;
	/** @format double */
	weight: number;
}

export interface CreateInvoiceBulkRequest {
	/** @format date-time */
	date: string;
	jobIds: number[];
}

export interface CreateJobGoodsRequest {
	constraints?: number[] | null;
	name: string;
	/** @format int32 */
	quantity: number;
	/** @format double */
	weight: number;
}

export interface CreateJobRequest {
	assignedTo?: string | null;
	containerNumber?: string | null;
	containerPin?: string | null;
	containerSealNumber?: string | null;
	/** @format int32 */
	customerId: number;
	/** @format date-time */
	endDate: string;
	/** @format int32 */
	endLocationId?: number | null;
	goodsInfo: CreateJobGoodsRequest;
	/** @format int32 */
	jobTypeId: number;
	notes?: string | null;
	/** @format double */
	price?: number | null;
	purchaseOrderNumber?: string | null;
	/** @format date-time */
	startDate: string;
	/** @format int32 */
	startLocationId?: number | null;
	useVat: boolean;
}

export interface CreateJobTypeRequest {
	defaultLegTypes: number[];
	isContainer: boolean;
	isDefaultZeroVat: boolean;
	isExport: boolean;
	isImport: boolean;
	name: string;
	nominalCode?: string | null;
	/** @format double */
	vatRate?: number | null;
}

export interface CreateLegsRequest {
	blank: LegModel;
	clearCustoms: LegModel;
	collectDeliver: LegModel;
	collection: LegModel;
	containerDropOff: LegModel;
	containerGoodsCollection: LegModel;
	containerGoodsDelivery: LegModel;
	containerPickUp: LegModel;
	/** @format double */
	cost?: number | null;
	/** @format int32 */
	currencyId?: number | null;
	delivery: LegModel;
	devan: LegModel;
	ferry: LegModel;
	goodsIds?: number[] | null;
	/** @format int32 */
	jobId?: number | null;
	loadAndLash: LegModel;
	/** @format int32 */
	loadId?: number | null;
	notes?: string | null;
	segment: LegModel;
	storage: LegModel;
	supplierInvoice: SupplierInvoiceType;
	/** @format date-time */
	supplierInvoiceDate?: string | null;
	supplierInvoiceNumber?: string | null;
}

export interface CreateLegsRequestV2 {
	t1?: string | null;
	/** @format date-time */
	clearanceDate?: string | null;
	clearanceLocation?: string | null;
	/** @format date-time */
	collectionDate?: string | null;
	/** @format int32 */
	collectionLocationId?: number | null;
	/** @format double */
	cost?: number | null;
	/** @format int32 */
	currencyId?: number | null;
	/** @format date-time */
	deliveryDate?: string | null;
	/** @format int32 */
	deliveryLocationId?: number | null;
	/** @format int32 */
	driverId?: number | null;
	ferryReferenceNumber?: string | null;
	ferryRoute?: string | null;
	/** @format date-time */
	ferrySailingDate?: string | null;
	goodsIds?: number[] | null;
	/** @format int32 */
	jobId?: number | null;
	legType: LegType;
	/** @format int32 */
	loadId?: number | null;
	mrn?: string | null;
	notes?: string | null;
	/** @format date-time */
	storageEndDate?: string | null;
	storageLocation?: string | null;
	/** @format date-time */
	storageStartDate?: string | null;
	/** @format int32 */
	subcontractorId?: number | null;
	supplierInvoice: SupplierInvoiceType;
	/** @format date-time */
	supplierInvoiceDate?: string | null;
	supplierInvoiceNumber?: string | null;
	/** @format int32 */
	trailerId?: number | null;
	/** @format int32 */
	trailerTypeId?: number | null;
	/** @format int32 */
	transferBusinessUnitId?: number | null;
	/** @format int32 */
	truckId?: number | null;
	/** @format int32 */
	truckTypeId?: number | null;
	ucr?: string | null;
}

export interface CreateLoadRequest {
	assignedTo?: string | null;
	/** @format date-time */
	endDate?: string | null;
	/** @format int32 */
	endLocationId?: number | null;
	/** @format int32 */
	loadTypeId: number;
	notes?: string | null;
	/** @format date-time */
	startDate: string;
	/** @format int32 */
	startLocationId: number;
	/** @format int32 */
	trailerId?: number | null;
}

export interface CreateLoadTypeRequest {
	name: string;
	/** @format int32 */
	order: number;
}

export interface CreateLocationRequest {
	address: string;
	/** @format int32 */
	customerId?: number | null;
	/** @format double */
	latitude: number;
	/** @format double */
	longitude: number;
	name: string;
	notes?: string | null;
}

export interface CreateQualificationRequest {
	constraintIds?: number[] | null;
	/** @format date-time */
	dateTaken: string;
	/** @format int32 */
	driverId: number;
	/** @format date-time */
	expirationDate: string;
	name: string;
}

export interface CreateSubcontractorRequest {
	constraintIds?: number[] | null;
	emails?: string[] | null;
	name: string;
	notes?: string | null;
	phones?: string[] | null;
	trailerTypeIds?: number[] | null;
	truckRegistrationNumbers?: string[] | null;
	truckTypeIds?: number[] | null;
}

export interface CreateTrailerRequest {
	chassisNo?: string | null;
	constraintIds?: number[] | null;
	europeanRegistrationNumber?: string | null;
	fleetNumber?: string | null;
	ministryNumber?: string | null;
	name: string;
	/** @format date-time */
	nextInspectionDate?: string | null;
	/** @format date-time */
	nextMOTDate?: string | null;
	notes?: string | null;
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	trailerTypeId: number;
}

export interface CreateTrailerTypeRequest {
	name: string;
}

export interface CreateTruckRequest {
	constraintIds?: number[] | null;
	name: string;
	/** @format date-time */
	nextInspectionTime: string;
	/** @format date-time */
	nextMOTDate: string;
	notes?: string | null;
	registration: string;
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	trailerId?: number | null;
	/** @format int32 */
	truckTypeId: number;
}

export interface CreateTruckTypeRequest {
	isArticulated: boolean;
	name: string;
}

export interface CurrencyExchangeRateListParams {
	request?: DataSourceRequest;
}

export interface CurrencyListParams {
	request?: DataSourceRequest;
}

export interface CustomAttributeData {
	attributeType: Type;
	constructor: ConstructorInfo;
	constructorArguments: CustomAttributeTypedArgument[];
	namedArguments: CustomAttributeNamedArgument[];
}

export interface CustomAttributeNamedArgument {
	isField: boolean;
	memberInfo: MemberInfo;
	memberName: string;
	typedValue: CustomAttributeTypedArgument;
}

export interface CustomAttributeTypedArgument {
	argumentType: Type;
	value?: any;
}

export type CustomerBulkCreatePayload = BulkCreateCustomerRequest[];

export interface CustomerListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

export interface CustomerLookupListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
}

/** @format int32 */
export type CustomerStatusType = 0 | 1 | 2 | 3;

export interface DataSourceRequest {
	aggregates?: AggregateDescriptor[] | null;
	caseSensitiveFilter: boolean;
	filters?: IFilterDescriptor[] | null;
	groupPaging: boolean;
	groups?: GroupDescriptor[] | null;
	includeSubGroupCount: boolean;
	isExcelExportRequest: boolean;
	/** @format int32 */
	page: number;
	/** @format int32 */
	pageSize: number;
	/** @format int32 */
	skip: number;
	sorts?: SortDescriptor[] | null;
	/** @format int32 */
	take: number;
}

export interface DetailedViewJobResponse {
	actions: JobActions;
	assignedTo?: string | null;
	assignedUser: ViewUserResponse;
	associatedLoads: ViewAssociatedBaseModel[];
	businessUnitId: string;
	containerNumber?: string | null;
	containerPin?: string | null;
	containerSealNumber?: string | null;
	/** @format double */
	cost?: number | null;
	createdBy?: string | null;
	createdByUser: ViewUserResponse;
	/** @format date-time */
	creationTime: string;
	currency: ViewCurrencyResponse;
	/** @format int32 */
	currencyId?: number | null;
	customer: ViewCustomerResponse;
	/** @format date-time */
	deliveryDate?: string | null;
	documents: ViewAttachmentResponse[];
	/** @format date-time */
	endDate: string;
	endLocation: ViewLocationBaseResponse;
	/** @format double */
	grossSum?: number | null;
	/** @format int32 */
	id: number;
	/** @format date-time */
	invoiceDate?: string | null;
	invoiceNumber?: string | null;
	jobType: ViewJobTypeResponse;
	legStatuses: number[];
	loads: ViewLoadResponse[];
	notes?: string | null;
	/** @format double */
	price: number;
	/** @format double */
	profitPercent?: number | null;
	purchaseOrderNumber?: string | null;
	/** @format date-time */
	startDate: string;
	startLocation: ViewLocationBaseResponse;
	status: JobStatusType;
	uniqueId: string;
	useVat: boolean;
	/** @format double */
	vatRate?: number | null;
	/** @format double */
	vatSum?: number | null;
}

export interface DetailedViewLoadResponse {
	actions: LoadActions;
	assignedTo?: string | null;
	assignedUser: ViewUserResponse;
	associatedJobs: ViewAssociatedBaseModel[];
	businessUnitId: string;
	/** @format double */
	cost?: number | null;
	createdBy?: string | null;
	createdByUser: ViewUserResponse;
	/** @format date-time */
	creationTime?: string | null;
	currency: ViewCurrencyResponse;
	/** @format int32 */
	currencyId?: number | null;
	documents: ViewAttachmentResponse[];
	driver: ViewContactBaseResponse;
	/** @format date-time */
	endDate?: string | null;
	endLocation: ViewLocationBaseResponse;
	/** @format int32 */
	id: number;
	loadType: ViewBaseResponse;
	notes?: string | null;
	/** @format int32 */
	numberOfGoods?: number | null;
	/** @format double */
	profit?: number | null;
	/** @format double */
	revenue?: number | null;
	/** @format double */
	sharedCost?: number | null;
	/** @format date-time */
	startDate?: string | null;
	startLocation: ViewLocationBaseResponse;
	status: LoadStatusType;
	subcontractor: ViewBaseResponse;
	trailer: ViewBaseResponse;
	trailerType: ViewBaseResponse;
	truck: ViewBaseResponse;
	truckType: ViewBaseResponse;
	uniqueId: string;
	/** @format double */
	weight?: number | null;
}

export interface DriverListParams {
	request?: DataSourceRequest;
}

/** @format int32 */
export type EventAttributes = 0 | 512 | 1024;

export interface EventInfo {
	addMethod: MethodInfo;
	attributes: EventAttributes;
	customAttributes: CustomAttributeData[];
	declaringType: Type;
	eventHandlerType: Type;
	isCollectible: boolean;
	isMulticast: boolean;
	isSpecialName: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	module: Module;
	name: string;
	raiseMethod: MethodInfo;
	reflectedType: Type;
	removeMethod: MethodInfo;
}

/** @format int32 */
export type FieldAttributes =
	| 0
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 16
	| 32
	| 64
	| 128
	| 256
	| 512
	| 1024
	| 4096
	| 8192
	| 32768
	| 38144;

export interface FieldInfo {
	attributes: FieldAttributes;
	customAttributes: CustomAttributeData[];
	declaringType: Type;
	fieldHandle: RuntimeFieldHandle;
	fieldType: Type;
	isAssembly: boolean;
	isCollectible: boolean;
	isFamily: boolean;
	isFamilyAndAssembly: boolean;
	isFamilyOrAssembly: boolean;
	isInitOnly: boolean;
	isLiteral: boolean;
	/** @deprecated */
	isNotSerialized: boolean;
	isPinvokeImpl: boolean;
	isPrivate: boolean;
	isPublic: boolean;
	isSecurityCritical: boolean;
	isSecuritySafeCritical: boolean;
	isSecurityTransparent: boolean;
	isSpecialName: boolean;
	isStatic: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	module: Module;
	name: string;
	reflectedType: Type;
}

export interface FleetFindListParams {
	/** @format int32 */
	DriverId?: number;
	/** @format int32 */
	SubcontractorId?: number;
	/** @format int32 */
	TrailerId?: number;
	/** @format int32 */
	TruckId?: number;
}

export interface FleetFindResonse {
	driver: ViewDriverResponse;
	subcontractor: ViewSubcontractorResponse;
	trailer: ViewTrailerResponse;
	truck: ViewTruckResponse;
}

export interface FleetLookup {
	/** @format int32 */
	driverId?: number | null;
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	trailerId?: number | null;
	/** @format int32 */
	truckId?: number | null;
}

/** @format int32 */
export type GenerateLoadReportModeType = 1 | 2 | 3;

/** @format int32 */
export type GenericParameterAttributes = 0 | 1 | 2 | 3 | 4 | 8 | 16 | 28 | 32;

export interface GetClosestDriversRecommendationRequest {
	/** @format int32 */
	locationId: number;
}

export interface GetJobParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

export interface GetLegParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

export interface GetMultiEditInput {
	/** @format int32 */
	jobId?: number | null;
	/** @format int32 */
	loadId?: number | null;
}

export interface GetVersionResponse {
	version?: string | null;
}

export interface GlobalErrorModel {
	/** @format int32 */
	errorCode?: number | null;
	errors: ValidationError[];
	innerException?: string | null;
	message: string;
	stackTrace?: string | null;
}

export interface Good {
	/** @format int32 */
	id: number;
	name: string;
	/** @format int32 */
	quantity: number;
}

export interface GoodFormDataResponse {
	/** @format date-time */
	collectionDate: string;
	/** @format int32 */
	collectionLocationId?: number | null;
	constraintIds: number[];
	/** @format date-time */
	deliveryDate: string;
	/** @format int32 */
	deliveryLocationId?: number | null;
	description?: string | null;
	/** @format int32 */
	id?: number | null;
	/** @format int32 */
	jobId: number;
	notes?: string | null;
	/** @format int32 */
	quantity?: number | null;
	/** @format double */
	revenue: number;
	/** @format double */
	weight?: number | null;
}

export interface GoodsInformationRequest {
	/** @format int32 */
	goodsId: number;
	haveDeliveryOnRoute: boolean;
	havePickupOnRoute: boolean;
}

export interface GoodsJobDetailParams {
	/** @format int32 */
	id: number;
	request?: DataSourceRequest;
}

export interface GoodsLoadDetailParams {
	/** @format int32 */
	id: number;
	request?: DataSourceRequest;
}

export interface GoodsLoadListParams {
	/** @format int32 */
	loadId?: number;
	request?: DataSourceRequest;
}

export interface GroupDescriptor {
	aggregateFunctions?: AggregateFunction[] | null;
	displayContent?: any;
	member?: string | null;
	memberType: Type;
	sortCompare: ClientHandlerDescriptor;
	sortDirection: ListSortDirection;
}

export interface GroupViewLegsResponse {
	blank: ViewLegsResponse[];
	clearCustoms: ViewLegsResponse[];
	collectDeliver: ViewLegsResponse[];
	collection: ViewLegsResponse[];
	containerDropOff: ViewLegsResponse[];
	containerGoodsCollection: ViewLegsResponse[];
	containerGoodsDelivery: ViewLegsResponse[];
	containerPickUp: ViewLegsResponse[];
	delivery: ViewLegsResponse[];
	devan: ViewLegsResponse[];
	ferry: ViewLegsResponse[];
	loadAndLash: ViewLegsResponse[];
	segment: ViewLegsResponse[];
	storage: ViewLegsResponse[];
}

export type ICustomAttributeProvider = object;

export interface IFilterDescriptor {
	caseSensitiveFilter: boolean;
}

export type IntPtr = object;

/** @format int32 */
export type InvoiceFrequencyType = 0 | 1 | 2;

export interface InvoiceGenerationResponse {
	customer: ViewCustomerResponse;
	/** @format uuid */
	eventId: string;
	jobIds: number[];
}

export interface InvoiceListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

/** @format int32 */
export type InvoiceSentMethodType = 0 | 1 | 2;

/** @format int32 */
export type InvoiceType = 0 | 1 | 2;

export interface JobActions {
	allowAudit: boolean;
	allowBulkLegsStatusChange: boolean;
	allowCancel: boolean;
	allowDuplicate: boolean;
	allowEdit: boolean;
	allowForceComplete: boolean;
	allowGenerateFileFront: boolean;
	allowMarkAsSent: boolean;
	allowPause: boolean;
	allowPreviewInvoice: boolean;
	allowReInvoice: boolean;
	allowResume: boolean;
	allowSendToInvoice: boolean;
	allowSendToInvoicingCheck: boolean;
	allowSendToOperations: boolean;
	allowViewDetails: boolean;
	isInvoiced: boolean;
}

export type JobChecklistsCreatePayload = ViewJobChecklistSettingModel[];

export interface JobDetailsResponse {
	actions: JobActions;
	assignedTo: string | null;
	/** @format double */
	cost: number | null;
	creationByUserName: string | null;
	/** @format date-time */
	creationTime: string;
	currencyCode: string | null;
	customerName: string;
	documents: JobDetailsResponseDocument[];
	endLocation: JobDetailsResponseLocation;
	goods: JobDetailsResponseGood[];
	/** @format int32 */
	id: number;
	legs: JobDetailsResponseLeg[];
	loads: JobDetailsResponseLoad[];
	notes: string | null;
	/** @format double */
	price: number | null;
	/** @format double */
	profitPercent: number | null;
	purchaseOrderNumber: string | null;
	startLocation: JobDetailsResponseLocation;
	status: JobStatusType;
	typeName: string;
	uniqueId: string | null;
	useVat: boolean;
	/** @format double */
	vatRate: number | null;
}

export interface JobDetailsResponseConstraint {
	/** @format int32 */
	id: number;
	name: string;
}

export interface JobDetailsResponseDocument {
	/** @format int32 */
	id: number;
	isInvoice: boolean;
	isPod: boolean;
	name: string;
}

export interface JobDetailsResponseGood {
	collectionLocation: JobDetailsResponseLocation;
	constraints: JobDetailsResponseConstraint[];
	currencyCode: string | null;
	deliveryLocation: JobDetailsResponseLocation;
	/** @format int32 */
	id: number;
	name: string;
	notes: string | null;
	/** @format double */
	quantity: number | null;
	/** @format double */
	revenue: number;
	uniqueId: string | null;
	/** @format double */
	weight: number | null;
}

export interface JobDetailsResponseLeg {
	t1: string | null;
	actions: LegActions;
	/** @format date-time */
	clearanceDate?: string | null;
	clearanceLocation?: string | null;
	collectionLocation: JobDetailsResponseLocation;
	/** @format double */
	cost: number | null;
	currencyCode: string | null;
	deliveryLocation: JobDetailsResponseLocation;
	documents: JobDetailsResponseDocument[];
	driverName: string | null;
	ferryReferenceNumber: string | null;
	ferryRoute: string | null;
	/** @format date-time */
	ferrySailingDate: string | null;
	goods: JobDetailsResponseLegGood[];
	/** @format int32 */
	id: number;
	mrn: string | null;
	notes: string | null;
	/** @format int32 */
	status: number;
	/** @format date-time */
	storageEndDate: string | null;
	storageLocation: string | null;
	/** @format date-time */
	storageStartDate: string | null;
	subcontractorName: string | null;
	subcontractorPhones: string | null;
	/** @format date-time */
	supplierInvoiceDate: string | null;
	supplierInvoiceNumber: string | null;
	trailerName: string | null;
	trailerTypeName: string | null;
	transferBusinessUnitName: string | null;
	truckName: string | null;
	truckTypeName: string | null;
	type: LegType;
	ucr: string | null;
	uniqueId: string | null;
}

export interface JobDetailsResponseLegGood {
	/** @format int32 */
	id: number;
	name: string;
}

export interface JobDetailsResponseLoad {
	/** @format int32 */
	id: number;
	uniqueId: string | null;
}

export interface JobDetailsResponseLocation {
	address: string | null;
	/** @format date-time */
	date: string | null;
	name: string | null;
}

/** @format int32 */
export type JobStatusType =
	| 0
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 13
	| 14
	| 15
	| 16
	| 17;

export interface JobTypeListParams {
	request?: DataSourceRequest;
}

/** @format int32 */
export type JobTypeStatus = 1 | 2 | 3;

export interface JobV2ListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
}

/** @format int32 */
export type LayoutKind = 0 | 2 | 3;

export interface LegActions {
	allowAudit: boolean;
	allowChangeStatus: boolean;
	allowDelete: boolean;
	allowEdit: boolean;
	allowGenerateCollectionNote: boolean;
	allowGenerateDeliveryTicket: boolean;
	allowGenerateSubcontractorOrder: boolean;
}

export interface LegChangeStatusCreateParams {
	/** @format int32 */
	legId: number;
	status?: LegStatusType;
}

export interface LegFormDataGoodsResponse {
	/** @format date-time */
	collectionDate?: string | null;
	/** @format int32 */
	collectionLocationId?: number | null;
	/** @format date-time */
	deliveryDate?: string | null;
	/** @format int32 */
	deliveryLocationId?: number | null;
	/** @format int32 */
	id: number;
}

export interface LegFormDataResponse {
	t1?: string | null;
	/** @format date-time */
	clearanceDate?: string | null;
	clearanceLocation?: string | null;
	/** @format date-time */
	collectionDate?: string | null;
	/** @format int32 */
	collectionLocationId?: number | null;
	/** @format double */
	cost?: number | null;
	/** @format int32 */
	currencyId?: number | null;
	/** @format date-time */
	deliveryDate?: string | null;
	/** @format int32 */
	deliveryLocationId?: number | null;
	/** @format int32 */
	driverId?: number | null;
	ferryReferenceNumber?: string | null;
	ferryRoute?: string | null;
	/** @format date-time */
	ferrySailingDate?: string | null;
	goodsIds: number[];
	/** @format int32 */
	id?: number | null;
	/** @format int32 */
	jobId?: number | null;
	legType: LegType;
	mrn?: string | null;
	notes?: string | null;
	/** @format date-time */
	storageEndDate?: string | null;
	storageLocation?: string | null;
	/** @format date-time */
	storageStartDate?: string | null;
	/** @format int32 */
	subcontractorId?: number | null;
	supplierInvoice: SupplierInvoiceType;
	/** @format date-time */
	supplierInvoiceDate?: string | null;
	supplierInvoiceNumber?: string | null;
	/** @format int32 */
	trailerId?: number | null;
	/** @format int32 */
	trailerTypeId?: number | null;
	transferBusinessUnitId?: string | null;
	/** @format int32 */
	truckId?: number | null;
	/** @format int32 */
	truckTypeId?: number | null;
	ucr?: string | null;
}

export interface LegModel {
	t1?: string | null;
	/** @format date-time */
	clearanceDate?: string | null;
	clearanceLocation?: string | null;
	/** @format date-time */
	collectionDate?: string | null;
	/** @format int32 */
	collectionLocationId?: number | null;
	/** @format date-time */
	deliveryDate?: string | null;
	/** @format int32 */
	deliveryLocationId?: number | null;
	/** @format int32 */
	driverId?: number | null;
	ferryReferenceNumber?: string | null;
	ferryRoute?: string | null;
	/** @format date-time */
	ferrySailingDate?: string | null;
	mrn?: string | null;
	/** @format date-time */
	storageEndDate?: string | null;
	storageLocation?: string | null;
	/** @format date-time */
	storageStartDate?: string | null;
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	trailerId?: number | null;
	/** @format int32 */
	trailerTypeId?: number | null;
	/** @format int32 */
	transferBusinessUnitId?: number | null;
	/** @format int32 */
	truckId?: number | null;
	/** @format int32 */
	truckTypeId?: number | null;
	ucr?: string | null;
}

export interface LegNoteData {
	/** @format int32 */
	id: number;
	name: string;
}

/** @format int32 */
export type LegStatusType =
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 13
	| 14
	| 15
	| 16
	| 17
	| 18
	| 19;

/** @format int32 */
export type LegType =
	| 0
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 13
	| 14;

export interface LegV2ListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
}

/** @format int32 */
export type ListSortDirection = 0 | 1;

export interface LoadActions {
	allowAudit: boolean;
	allowCancel: boolean;
	allowEdit: boolean;
	allowGenerateAllDocuments: boolean;
	allowGenerateManifest: boolean;
	allowGenerateManifestAndCmr: boolean;
	allowGenerateManifestAndDeliveryTickets: boolean;
	allowPause: boolean;
	allowViewClearCustomsDoc: boolean;
	allowViewDetails: boolean;
}

export interface LoadDetailsResponse {
	actions: LoadActions;
	assignedTo: string | null;
	/** @format double */
	cost: number | null;
	creationByUserName: string | null;
	/** @format date-time */
	creationTime: string;
	/** @format int32 */
	id: number;
	notes: string | null;
	/** @format double */
	profit: number | null;
	/** @format double */
	revenue: number | null;
	/** @format double */
	sharedCost: number | null;
	status: LoadStatusType;
	typeName: string;
	uniqueId: string | null;
}

export interface LoadListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

/** @format int32 */
export type LoadStatusType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export interface LoadTypeListParams {
	request?: DataSourceRequest;
}

export interface LoadV2ListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
}

export interface LocationListParams {
	request?: DataSourceRequest;
}

export type LocationLookupCreatePayload = number[];

export interface MapSettings {
	/** @format double */
	mapCenterLatitude: number;
	/** @format double */
	mapCenterLongitude: number;
}

export interface MemberInfo {
	customAttributes: CustomAttributeData[];
	declaringType: Type;
	isCollectible: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	module: Module;
	name: string;
	reflectedType: Type;
}

/** @format int32 */
export type MemberTypes = 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 191;

/** @format int32 */
export type MethodAttributes =
	| 0
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 16
	| 32
	| 64
	| 128
	| 256
	| 512
	| 1024
	| 2048
	| 4096
	| 8192
	| 16384
	| 32768
	| 53248;

export interface MethodBase {
	attributes: MethodAttributes;
	callingConvention: CallingConventions;
	containsGenericParameters: boolean;
	customAttributes: CustomAttributeData[];
	declaringType: Type;
	isAbstract: boolean;
	isAssembly: boolean;
	isCollectible: boolean;
	isConstructedGenericMethod: boolean;
	isConstructor: boolean;
	isFamily: boolean;
	isFamilyAndAssembly: boolean;
	isFamilyOrAssembly: boolean;
	isFinal: boolean;
	isGenericMethod: boolean;
	isGenericMethodDefinition: boolean;
	isHideBySig: boolean;
	isPrivate: boolean;
	isPublic: boolean;
	isSecurityCritical: boolean;
	isSecuritySafeCritical: boolean;
	isSecurityTransparent: boolean;
	isSpecialName: boolean;
	isStatic: boolean;
	isVirtual: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	methodHandle: RuntimeMethodHandle;
	methodImplementationFlags: MethodImplAttributes;
	module: Module;
	name: string;
	reflectedType: Type;
}

/** @format int32 */
export type MethodImplAttributes =
	| 0
	| 1
	| 2
	| 3
	| 4
	| 8
	| 16
	| 32
	| 64
	| 128
	| 256
	| 512
	| 4096
	| 65535;

export interface MethodInfo {
	attributes: MethodAttributes;
	callingConvention: CallingConventions;
	containsGenericParameters: boolean;
	customAttributes: CustomAttributeData[];
	declaringType: Type;
	isAbstract: boolean;
	isAssembly: boolean;
	isCollectible: boolean;
	isConstructedGenericMethod: boolean;
	isConstructor: boolean;
	isFamily: boolean;
	isFamilyAndAssembly: boolean;
	isFamilyOrAssembly: boolean;
	isFinal: boolean;
	isGenericMethod: boolean;
	isGenericMethodDefinition: boolean;
	isHideBySig: boolean;
	isPrivate: boolean;
	isPublic: boolean;
	isSecurityCritical: boolean;
	isSecuritySafeCritical: boolean;
	isSecurityTransparent: boolean;
	isSpecialName: boolean;
	isStatic: boolean;
	isVirtual: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	methodHandle: RuntimeMethodHandle;
	methodImplementationFlags: MethodImplAttributes;
	module: Module;
	name: string;
	reflectedType: Type;
	returnParameter: ParameterInfo;
	returnType: Type;
	returnTypeCustomAttributes: ICustomAttributeProvider;
}

export interface Module {
	assembly: Assembly;
	customAttributes: CustomAttributeData[];
	fullyQualifiedName: string;
	/** @format int32 */
	mdStreamVersion: number;
	/** @format int32 */
	metadataToken: number;
	moduleHandle: ModuleHandle;
	/** @format uuid */
	moduleVersionId: string;
	name: string;
	scopeName: string;
}

export interface ModuleHandle {
	/** @format int32 */
	mdStreamVersion: number;
}

export interface MultiEdit {
	/** @format date-time */
	endDate?: string | null;
	/** @format int32 */
	endLocationId?: number | null;
	/** @format int32 */
	id: number;
	name?: string | null;
	/** @format date-time */
	startDate?: string | null;
	/** @format int32 */
	startLocationId?: number | null;
	type: MultiEditType;
}

/** @format int32 */
export type MultiEditType = 0 | 1 | 2 | 3;

export interface ObjectObjectFunc {
	method: MethodInfo;
	target?: any;
}

/** @format int32 */
export type ObjectType = 0 | 1 | 2;

/** @format int32 */
export type ParameterAttributes =
	| 0
	| 1
	| 2
	| 4
	| 8
	| 16
	| 4096
	| 8192
	| 16384
	| 32768
	| 61440;

export interface ParameterInfo {
	attributes: ParameterAttributes;
	customAttributes: CustomAttributeData[];
	defaultValue?: any;
	hasDefaultValue: boolean;
	isIn: boolean;
	isLcid: boolean;
	isOptional: boolean;
	isOut: boolean;
	isRetval: boolean;
	member: MemberInfo;
	/** @format int32 */
	metadataToken: number;
	name?: string | null;
	parameterType: Type;
	/** @format int32 */
	position: number;
	rawDefaultValue?: any;
}

export interface PartialUpdateJobRequest {
	/** @format int32 */
	customerId: number;
	/** @format int32 */
	id: number;
	/** @format double */
	price?: number | null;
	purchaseOrderNumber?: string | null;
}

export interface PartialUpdateLegsRequest {
	/** @format int32 */
	id: number;
	/** @format date-time */
	supplierInvoiceDate: string;
	supplierInvoiceNumber: string;
}

/** @format int32 */
export type PropertyAttributes =
	| 0
	| 512
	| 1024
	| 4096
	| 8192
	| 16384
	| 32768
	| 62464;

export interface PropertyInfo {
	attributes: PropertyAttributes;
	canRead: boolean;
	canWrite: boolean;
	customAttributes: CustomAttributeData[];
	declaringType: Type;
	getMethod: MethodInfo;
	isCollectible: boolean;
	isSpecialName: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	module: Module;
	name: string;
	propertyType: Type;
	reflectedType: Type;
	setMethod: MethodInfo;
}

export interface QualificationListParams {
	request?: DataSourceRequest;
}

export interface RandomDriversRecommendation {
	/** @format int32 */
	driverId: number;
	driverName: string;
	/** @format int32 */
	trailerId: number | null;
	trailerName: string | null;
	/** @format int32 */
	truckId: number | null;
	truckName: string | null;
}

export interface RecommendationFeedbackInput {
	feedback?: string | null;
	/** @format int32 */
	index?: number | null;
	/** @format int32 */
	legId: number;
	recommendations: Recommendations;
	type?: string | null;
}

export interface Recommendations {
	closestDrivers: ClosestDriversRecommendation[];
	randomDrivers: RandomDriversRecommendation[];
}

export interface RuntimeFieldHandle {
	value: IntPtr;
}

export interface RuntimeMethodHandle {
	value: IntPtr;
}

export interface RuntimeTypeHandle {
	value: IntPtr;
}

export interface SearchListParams {
	search?: string;
}

/** @format int32 */
export type SecurityRuleSet = 0 | 1 | 2;

export interface SortDescriptor {
	member?: string | null;
	sortCompare: ClientHandlerDescriptor;
	sortDirection: ListSortDirection;
}

/** @format int32 */
export type Status = 0 | 1 | 2;

export interface StringStringFAnonymousType0 {
	message?: string | null;
	status?: string | null;
}

export interface StructLayoutAttribute {
	typeId: any;
	value: LayoutKind;
}

export interface SubcontractorListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

export interface SubcontractorLookupListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
}

/** @format int32 */
export type SupplierInvoiceType = 0 | 1 | 2 | 3;

export interface TrailerListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

export interface TrailerLookupListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
}

export interface TrailerTypeListParams {
	request?: DataSourceRequest;
}

export interface TruckListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
	request?: DataSourceRequest;
}

export interface TruckLookupListParams {
	AssignedTo?: string[];
	ConstraintIds?: number[];
	CurrenciesIds?: number[];
	CustomerIds?: number[];
	/** @format date-time */
	DateFrom?: string;
	/** @format date-time */
	DateTo?: string;
	DriverIds?: number[];
	Ids?: number[];
	JobIds?: number[];
	JobTypeIds?: number[];
	LegTypeIds?: number[];
	LoadIds?: number[];
	StatusIds?: number[];
	TrailerTypeIds?: number[];
	TruckTypeIds?: number[];
}

export interface TruckTypeListParams {
	request?: DataSourceRequest;
}

export interface Type {
	assembly: Assembly;
	assemblyQualifiedName?: string | null;
	attributes: TypeAttributes;
	baseType: Type;
	containsGenericParameters: boolean;
	customAttributes: CustomAttributeData[];
	declaringMethod: MethodBase;
	declaringType: Type;
	fullName?: string | null;
	genericParameterAttributes: GenericParameterAttributes;
	/** @format int32 */
	genericParameterPosition: number;
	genericTypeArguments: Type[];
	/** @format uuid */
	guid: string;
	hasElementType: boolean;
	isAbstract: boolean;
	isAnsiClass: boolean;
	isArray: boolean;
	isAutoClass: boolean;
	isAutoLayout: boolean;
	isByRef: boolean;
	isByRefLike: boolean;
	isCOMObject: boolean;
	isClass: boolean;
	isCollectible: boolean;
	isConstructedGenericType: boolean;
	isContextful: boolean;
	isEnum: boolean;
	isExplicitLayout: boolean;
	isFunctionPointer: boolean;
	isGenericMethodParameter: boolean;
	isGenericParameter: boolean;
	isGenericType: boolean;
	isGenericTypeDefinition: boolean;
	isGenericTypeParameter: boolean;
	isImport: boolean;
	isInterface: boolean;
	isLayoutSequential: boolean;
	isMarshalByRef: boolean;
	isNested: boolean;
	isNestedAssembly: boolean;
	isNestedFamANDAssem: boolean;
	isNestedFamORAssem: boolean;
	isNestedFamily: boolean;
	isNestedPrivate: boolean;
	isNestedPublic: boolean;
	isNotPublic: boolean;
	isPointer: boolean;
	isPrimitive: boolean;
	isPublic: boolean;
	isSZArray: boolean;
	isSealed: boolean;
	isSecurityCritical: boolean;
	isSecuritySafeCritical: boolean;
	isSecurityTransparent: boolean;
	/** @deprecated */
	isSerializable: boolean;
	isSignatureType: boolean;
	isSpecialName: boolean;
	isTypeDefinition: boolean;
	isUnicodeClass: boolean;
	isUnmanagedFunctionPointer: boolean;
	isValueType: boolean;
	isVariableBoundArray: boolean;
	isVisible: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	module: Module;
	name: string;
	namespace?: string | null;
	reflectedType: Type;
	structLayoutAttribute: StructLayoutAttribute;
	typeHandle: RuntimeTypeHandle;
	typeInitializer: ConstructorInfo;
	underlyingSystemType: Type;
}

/** @format int32 */
export type TypeAttributes =
	| 0
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 16
	| 24
	| 32
	| 128
	| 256
	| 1024
	| 2048
	| 4096
	| 8192
	| 16384
	| 65536
	| 131072
	| 196608
	| 262144
	| 264192
	| 1048576
	| 12582912;

export interface TypeInfo {
	assembly: Assembly;
	assemblyQualifiedName?: string | null;
	attributes: TypeAttributes;
	baseType: Type;
	containsGenericParameters: boolean;
	customAttributes: CustomAttributeData[];
	declaredConstructors: ConstructorInfo[];
	declaredEvents: EventInfo[];
	declaredFields: FieldInfo[];
	declaredMembers: MemberInfo[];
	declaredMethods: MethodInfo[];
	declaredNestedTypes: TypeInfo[];
	declaredProperties: PropertyInfo[];
	declaringMethod: MethodBase;
	declaringType: Type;
	fullName?: string | null;
	genericParameterAttributes: GenericParameterAttributes;
	/** @format int32 */
	genericParameterPosition: number;
	genericTypeArguments: Type[];
	genericTypeParameters: Type[];
	/** @format uuid */
	guid: string;
	hasElementType: boolean;
	implementedInterfaces: Type[];
	isAbstract: boolean;
	isAnsiClass: boolean;
	isArray: boolean;
	isAutoClass: boolean;
	isAutoLayout: boolean;
	isByRef: boolean;
	isByRefLike: boolean;
	isCOMObject: boolean;
	isClass: boolean;
	isCollectible: boolean;
	isConstructedGenericType: boolean;
	isContextful: boolean;
	isEnum: boolean;
	isExplicitLayout: boolean;
	isFunctionPointer: boolean;
	isGenericMethodParameter: boolean;
	isGenericParameter: boolean;
	isGenericType: boolean;
	isGenericTypeDefinition: boolean;
	isGenericTypeParameter: boolean;
	isImport: boolean;
	isInterface: boolean;
	isLayoutSequential: boolean;
	isMarshalByRef: boolean;
	isNested: boolean;
	isNestedAssembly: boolean;
	isNestedFamANDAssem: boolean;
	isNestedFamORAssem: boolean;
	isNestedFamily: boolean;
	isNestedPrivate: boolean;
	isNestedPublic: boolean;
	isNotPublic: boolean;
	isPointer: boolean;
	isPrimitive: boolean;
	isPublic: boolean;
	isSZArray: boolean;
	isSealed: boolean;
	isSecurityCritical: boolean;
	isSecuritySafeCritical: boolean;
	isSecurityTransparent: boolean;
	/** @deprecated */
	isSerializable: boolean;
	isSignatureType: boolean;
	isSpecialName: boolean;
	isTypeDefinition: boolean;
	isUnicodeClass: boolean;
	isUnmanagedFunctionPointer: boolean;
	isValueType: boolean;
	isVariableBoundArray: boolean;
	isVisible: boolean;
	memberType: MemberTypes;
	/** @format int32 */
	metadataToken: number;
	module: Module;
	name: string;
	namespace?: string | null;
	reflectedType: Type;
	structLayoutAttribute: StructLayoutAttribute;
	typeHandle: RuntimeTypeHandle;
	typeInitializer: ConstructorInfo;
	underlyingSystemType: Type;
}

export interface UpdateChecklistRequest {
	description?: string | null;
	/** @format int32 */
	id: number;
	items: string[];
	name: string;
}

export interface UpdateConstraintRequest {
	/** @format int32 */
	id: number;
	name: string;
}

export interface UpdateCurrencyExchangeRateRequest {
	/** @format double */
	exchangeRate: number;
	/** @format int32 */
	fromCurrencyId: number;
	/** @format int32 */
	id: number;
	/** @format int32 */
	toCurrencyId: number;
}

export interface UpdateCurrencyRequest {
	code: string;
	/** @format int32 */
	id: number;
}

export interface UpdateCustomerRequest {
	accountCode: string;
	address?: string | null;
	/** @format int32 */
	checklistId?: number | null;
	/** @format int32 */
	currencyId: number;
	emails?: string[] | null;
	/** @format int32 */
	id: number;
	includePodAttachmentToInvoiceEmail: boolean;
	invoiceFrequency: InvoiceFrequencyType;
	invoiceSentMethod: InvoiceSentMethodType;
	invoiceTemplate?: string | null;
	invoiceType: InvoiceType;
	isEuCustomer: boolean;
	isInSync: boolean;
	isPurchaseOrderNumberMandatory: boolean;
	mergeAttachmentInOneFile: boolean;
	name: string;
	phones?: string[] | null;
	status: CustomerStatusType;
	vatNumber?: string | null;
}

export interface UpdateDriverRequest {
	emails: string[];
	firstName: string;
	/** @format int32 */
	id: number;
	lastName: string;
	/** @format date-time */
	licenceExpirationDate: string;
	notes?: string | null;
	phones: string[];
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	truckId?: number | null;
}

export interface UpdateGoodsRequest {
	/** @format date-time */
	collectionDate: string;
	/** @format int32 */
	collectionLocationId?: number | null;
	constraintIds?: number[] | null;
	/** @format int32 */
	currencyId: number;
	/** @format date-time */
	deliveryDate: string;
	/** @format int32 */
	deliveryLocationId?: number | null;
	dimensions?: string | null;
	/** @format int32 */
	id: number;
	isFreeOfCharge: boolean;
	/** @format int32 */
	jobId: number;
	name: string;
	notes?: string | null;
	/** @format int32 */
	quantity: number;
	/** @format double */
	revenue: number;
	/** @format double */
	weight: number;
}

export interface UpdateJobIsNewRequest {
	/** @format int32 */
	id: number;
	isNew: boolean;
}

export interface UpdateJobRequest {
	assignedTo?: string | null;
	/** @format date-time */
	closed?: string | null;
	containerNumber?: string | null;
	containerPin?: string | null;
	containerSealNumber?: string | null;
	/** @format int32 */
	customerId: number;
	/** @format date-time */
	endDate: string;
	/** @format int32 */
	endLocationId?: number | null;
	goodsInfo: CreateJobGoodsRequest;
	/** @format int32 */
	id: number;
	/** @format int32 */
	jobTypeId: number;
	notes?: string | null;
	/** @format double */
	price?: number | null;
	purchaseOrderNumber?: string | null;
	/** @format date-time */
	startDate: string;
	/** @format int32 */
	startLocationId?: number | null;
	useVat: boolean;
}

export interface UpdateJobTypeRequest {
	defaultLegTypes: number[];
	/** @format int32 */
	id: number;
	isContainer: boolean;
	isDefaultZeroVat: boolean;
	isExport: boolean;
	isImport: boolean;
	name: string;
	nominalCode?: string | null;
	status: JobTypeStatus;
	/** @format double */
	vatRate?: number | null;
}

export interface UpdateLegsIsNewRequest {
	/** @format int32 */
	id: number;
	isNew: boolean;
}

export interface UpdateLegsRequest {
	blank: LegModel;
	clearCustoms: LegModel;
	collectDeliver: LegModel;
	collection: LegModel;
	containerDropOff: LegModel;
	containerGoodsCollection: LegModel;
	containerGoodsDelivery: LegModel;
	containerPickUp: LegModel;
	/** @format double */
	cost?: number | null;
	/** @format int32 */
	currencyId?: number | null;
	delivery: LegModel;
	devan: LegModel;
	ferry: LegModel;
	goodsIds?: number[] | null;
	/** @format int32 */
	id: number;
	/** @format int32 */
	jobId?: number | null;
	loadAndLash: LegModel;
	/** @format int32 */
	loadId?: number | null;
	notes?: string | null;
	segment: LegModel;
	storage: LegModel;
	supplierInvoice: SupplierInvoiceType;
	/** @format date-time */
	supplierInvoiceDate?: string | null;
	supplierInvoiceNumber?: string | null;
}

export interface UpdateLegsRequestV2 {
	t1?: string | null;
	/** @format date-time */
	clearanceDate?: string | null;
	clearanceLocation?: string | null;
	/** @format date-time */
	collectionDate?: string | null;
	/** @format int32 */
	collectionLocationId?: number | null;
	/** @format double */
	cost?: number | null;
	/** @format int32 */
	currencyId?: number | null;
	/** @format date-time */
	deliveryDate?: string | null;
	/** @format int32 */
	deliveryLocationId?: number | null;
	/** @format int32 */
	driverId?: number | null;
	ferryReferenceNumber?: string | null;
	ferryRoute?: string | null;
	/** @format date-time */
	ferrySailingDate?: string | null;
	goodsIds?: number[] | null;
	/** @format int32 */
	id: number;
	/** @format int32 */
	jobId?: number | null;
	legType: LegType;
	/** @format int32 */
	loadId?: number | null;
	mrn?: string | null;
	notes?: string | null;
	/** @format date-time */
	storageEndDate?: string | null;
	storageLocation?: string | null;
	/** @format date-time */
	storageStartDate?: string | null;
	/** @format int32 */
	subcontractorId?: number | null;
	supplierInvoice: SupplierInvoiceType;
	/** @format date-time */
	supplierInvoiceDate?: string | null;
	supplierInvoiceNumber?: string | null;
	/** @format int32 */
	trailerId?: number | null;
	/** @format int32 */
	trailerTypeId?: number | null;
	/** @format int32 */
	transferBusinessUnitId?: number | null;
	/** @format int32 */
	truckId?: number | null;
	/** @format int32 */
	truckTypeId?: number | null;
	ucr?: string | null;
}

export interface UpdateLoadRequest {
	assignedTo?: string | null;
	/** @format date-time */
	endDate?: string | null;
	/** @format int32 */
	endLocationId?: number | null;
	/** @format int32 */
	id: number;
	/** @format int32 */
	loadTypeId: number;
	notes?: string | null;
	/** @format date-time */
	startDate: string;
	/** @format int32 */
	startLocationId: number;
	/** @format int32 */
	trailerId?: number | null;
}

export interface UpdateLoadTypeRequest {
	/** @format int32 */
	id: number;
	name: string;
	/** @format int32 */
	order: number;
}

export interface UpdateLocationRequest {
	address: string;
	/** @format int32 */
	customerId?: number | null;
	/** @format int32 */
	id: number;
	/** @format double */
	latitude: number;
	/** @format double */
	longitude: number;
	name: string;
	notes?: string | null;
}

export interface UpdateQualificationRequest {
	constraintIds?: number[] | null;
	/** @format date-time */
	dateTaken: string;
	/** @format int32 */
	driverId: number;
	/** @format date-time */
	expirationDate: string;
	/** @format int32 */
	id: number;
	name: string;
}

export interface UpdateSubcontractorRequest {
	constraintIds?: number[] | null;
	emails?: string[] | null;
	/** @format int32 */
	id: number;
	name: string;
	notes?: string | null;
	phones?: string[] | null;
	trailerTypeIds?: number[] | null;
	truckRegistrationNumbers?: string[] | null;
	truckTypeIds?: number[] | null;
}

export interface UpdateTrailerRequest {
	chassisNo?: string | null;
	constraintIds?: number[] | null;
	europeanRegistrationNumber?: string | null;
	fleetNumber?: string | null;
	/** @format int32 */
	id: number;
	ministryNumber?: string | null;
	name: string;
	/** @format date-time */
	nextInspectionDate?: string | null;
	/** @format date-time */
	nextMOTDate?: string | null;
	notes?: string | null;
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	trailerTypeId: number;
}

export interface UpdateTrailerTypeRequest {
	/** @format int32 */
	id: number;
	name: string;
}

export interface UpdateTruckRequest {
	constraintIds?: number[] | null;
	/** @format int32 */
	id: number;
	name: string;
	/** @format date-time */
	nextInspectionTime: string;
	/** @format date-time */
	nextMOTDate: string;
	notes?: string | null;
	registration: string;
	/** @format int32 */
	subcontractorId?: number | null;
	/** @format int32 */
	trailerId?: number | null;
	/** @format int32 */
	truckTypeId: number;
}

export interface UpdateTruckTypeRequest {
	/** @format int32 */
	id: number;
	isArticulated: boolean;
	name: string;
}

/** @format int32 */
export type UserStatus = 0 | 1 | 2;

export interface ValidationError {
	code?: string | null;
	message?: string | null;
	parameters?: Record<string, string | null>;
}

export interface ViewAssociatedBaseModel {
	/** @format int32 */
	id: number;
	uniqueId?: string | null;
}

export interface ViewAttachmentResponse {
	/** @format int32 */
	id: number;
	isInvoice: boolean;
	isPod: boolean;
	name: string;
	path: string;
}

export interface ViewBaseResponse {
	/** @format int32 */
	id: number;
	name?: string | null;
}

export interface ViewChecklistResponse {
	description?: string | null;
	/** @format int32 */
	id: number;
	items: string[];
	name: string;
}

export interface ViewChecklistResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewChecklistResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewConstraintResponse {
	/** @format int32 */
	id: number;
	name: string;
}

export interface ViewConstraintResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewConstraintResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewContactBaseResponse {
	/** @format int32 */
	id: number;
	name: string;
	phoneNumber?: string | null;
	phones: string[];
}

export interface ViewCurrencyExchangeRateResponse {
	/** @format double */
	exchangeRate: number;
	fromCurrency: ViewCurrencyResponse;
	/** @format int32 */
	id: number;
	toCurrency: ViewCurrencyResponse;
}

export interface ViewCurrencyExchangeRateResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewCurrencyExchangeRateResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewCurrencyResponse {
	code: string;
	/** @format int32 */
	id: number;
}

export interface ViewCurrencyResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewCurrencyResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewCustomerResponse {
	accountCode: string;
	address?: string | null;
	checklist: ViewBaseResponse;
	currency: ViewCurrencyResponse;
	emails: string[];
	/** @format int32 */
	id: number;
	includePodAttachmentToInvoiceEmail: boolean;
	invoiceFrequency: InvoiceFrequencyType;
	invoiceSentMethod: InvoiceSentMethodType;
	invoiceTemplate?: string | null;
	invoiceType: InvoiceType;
	isEuCustomer: boolean;
	isInSync: boolean;
	isPurchaseOrderNumberMandatory: boolean;
	mergeAttachmentInOneFile: boolean;
	name: string;
	phones: string[];
	status: CustomerStatusType;
	vatNumber?: string | null;
}

export interface ViewCustomerResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewCustomerResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewDisplayLegsResponse {
	actions: LegActions;
	assignedTo?: string | null;
	assignedToName?: string | null;
	availableStatuses: number[];
	/** @format date-time */
	collectionDate?: string | null;
	collectionLocationAddress?: string | null;
	collectionLocationName?: string | null;
	containerNumber?: string | null;
	containerPin?: string | null;
	containerSealNumber?: string | null;
	/** @format double */
	cost?: number | null;
	currencyCode?: string | null;
	/** @format int32 */
	currencyId?: number | null;
	/** @format int32 */
	customerId?: number | null;
	customerName?: string | null;
	/** @format date-time */
	deliveryDate?: string | null;
	deliveryLocationAddress?: string | null;
	deliveryLocationName?: string | null;
	driverName?: string | null;
	/** @format int32 */
	id: number;
	isNew: boolean;
	/** @format int32 */
	jobId?: number | null;
	/** @format int32 */
	jobTypeId?: number | null;
	/** @format int32 */
	loadId?: number | null;
	/** @format int32 */
	loadTypeId?: number | null;
	notes?: string | null;
	parentEntityTypeName?: string | null;
	parentEntityUniqueId?: string | null;
	status: LegStatusType;
	/** @format int32 */
	subcontractorId?: number | null;
	subcontractorName?: string | null;
	supplierInvoice: SupplierInvoiceType;
	/** @format date-time */
	supplierInvoiceDate?: string | null;
	supplierInvoiceNumber?: string | null;
	trailerName?: string | null;
	truckName?: string | null;
	type: LegType;
	underwayLegName?: string | null;
	uniqueId?: string | null;
}

export interface ViewDisplayLegsResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewDisplayLegsResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewDriverLastAssignedLegResponse {
	/** @format int32 */
	id: number;
	trailer: ViewBaseResponse;
	/** @format int32 */
	trailerId?: number | null;
	truck: ViewBaseResponse;
	/** @format int32 */
	truckId?: number | null;
}

export interface ViewDriverResponse {
	emails: string[];
	firstName: string;
	fullName: string;
	/** @format int32 */
	id: number;
	lastName: string;
	/** @format date-time */
	licenceExpirationDate: string;
	notes?: string | null;
	phones: string[];
	subcontractor: ViewBaseResponse;
	/** @format int32 */
	subcontractorId?: number | null;
	truck: ViewBaseResponse;
	/** @format int32 */
	truckId?: number | null;
}

export interface ViewDriverResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewDriverResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewGoodsForLoadRequest {
	/** @format date-time */
	collectionDate: string;
	collectionLocationName?: string | null;
	constraints: ViewBaseResponse[];
	customerName?: string | null;
	/** @format date-time */
	deliveryDate?: string | null;
	deliveryLocationName?: string | null;
	/** @format int32 */
	id: number;
	jobStatus: JobStatusType;
	jobTypeName?: string | null;
	jobUniqueId?: string | null;
	name: string;
	/** @format int32 */
	quantity?: number | null;
	uniqueId: string;
	/** @format double */
	weight?: number | null;
}

export interface ViewGoodsForLoadRequestDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewGoodsForLoadRequest[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewGoodsResponse {
	clearCustomsLegStatusId: LegStatusType;
	/** @format date-time */
	collectionDate: string;
	collectionLocation: ViewLocationBaseResponse;
	constraints: ViewBaseResponse[];
	currency: ViewCurrencyResponse;
	/** @format date-time */
	deliveryDate: string;
	deliveryLocation: ViewLocationBaseResponse;
	dimensions?: string | null;
	/** @format int32 */
	id: number;
	isFreeOfCharge: boolean;
	/** @format int32 */
	jobId: number;
	jobNumber?: string | null;
	name: string;
	notes?: string | null;
	/** @format int32 */
	quantity: number;
	/** @format double */
	revenue: number;
	uniqueId?: string | null;
	/** @format double */
	weight: number;
}

export interface ViewGoodsResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewGoodsResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewInvoiceResponse {
	actions: JobActions;
	assignedTo?: string | null;
	assignedToName?: string | null;
	/** @format double */
	cost?: number | null;
	customerCurrencyCode?: string | null;
	/** @format int32 */
	customerId: number;
	customerInvoiceType: InvoiceType;
	customerName?: string | null;
	/** @format date-time */
	endDate: string;
	/** @format double */
	grossSum?: number | null;
	/** @format int32 */
	id: number;
	/** @format date-time */
	invoiceDate?: string | null;
	invoiceNumber?: string | null;
	latestJobHistoryEventValue?: string | null;
	/** @format double */
	price?: number | null;
	purchaseOrderNumber?: string | null;
	/** @format date-time */
	startDate: string;
	status: JobStatusType;
	/** @format int32 */
	totalLegsNeedingPods: number;
	/** @format int32 */
	totalLegsWithPods: number;
	uniqueId?: string | null;
	/** @format double */
	vatRate?: number | null;
}

export interface ViewInvoiceResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewInvoiceResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewJobChecklistSettingModel {
	checked: boolean;
	name: string;
}

export interface ViewJobResponse {
	actions: JobActions;
	assignedTo?: string | null;
	assignedToName?: string | null;
	/** @format double */
	cost?: number | null;
	currencyCode?: string | null;
	/** @format int32 */
	currencyId?: number | null;
	customerName?: string | null;
	driverName?: string | null;
	/** @format date-time */
	endDate: string;
	endLocationName?: string | null;
	/** @format int32 */
	id: number;
	isNew: boolean;
	/** @format int32 */
	jobTypeId: number;
	jobTypeName?: string | null;
	/** @format double */
	price?: number | null;
	purchaseOrderNumber?: string | null;
	/** @format date-time */
	startDate: string;
	startLocationName?: string | null;
	status: JobStatusType;
	subcontractorName?: string | null;
	uniqueId?: string | null;
}

export interface ViewJobResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewJobResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewJobTypeResponse {
	defaultLegTypes: number[];
	/** @format int32 */
	id: number;
	isContainer: boolean;
	isDefaultZeroVat: boolean;
	isExport: boolean;
	isImport: boolean;
	name: string;
	nominalCode?: string | null;
	status: JobTypeStatus;
	/** @format double */
	vatRate?: number | null;
}

export interface ViewJobTypeResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewJobTypeResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewLegStatusResponse {
	color: string;
	/** @format int32 */
	id: number;
	legTypes: LegType[];
	name: string;
}

export interface ViewLegsForDriverResponse {
	availableStatuses: number[];
	/** @format date-time */
	collectionDate: string | null;
	collectionLocationAddress: string | null;
	collectionLocationName: string | null;
	containerNumber: string | null;
	containerPin: string | null;
	containerSealNumber: string | null;
	customerName: string | null;
	/** @format date-time */
	deliveryDate: string | null;
	deliveryLocationAddress: string | null;
	deliveryLocationName: string | null;
	goods: Good[];
	/** @format int32 */
	id: number;
	loadGoods: Good[];
	notes: string | null;
	/** @format int32 */
	podCount: number;
	status: LegStatusType;
	trailerName: string | null;
	truckName: string | null;
	uniqueId: string | null;
}

export interface ViewLegsResponse {
	t1?: string | null;
	actions: LegActions;
	assignedTo?: string | null;
	availableStatuses: number[];
	businessUnit: ViewBaseResponse;
	/** @format date-time */
	clearanceDate?: string | null;
	clearanceLocation?: string | null;
	/** @format date-time */
	collectionDate?: string | null;
	collectionLocation: ViewLocationBaseResponse;
	/** @format double */
	cost?: number | null;
	currency: ViewCurrencyResponse;
	/** @format int32 */
	currencyId?: number | null;
	customer: ViewCustomerResponse;
	/** @format date-time */
	dateClosed?: string | null;
	/** @format date-time */
	deliveryDate?: string | null;
	deliveryLocation: ViewLocationBaseResponse;
	documents: ViewAttachmentResponse[];
	driver: ViewContactBaseResponse;
	ferryReferenceNumber?: string | null;
	ferryRoute?: string | null;
	/** @format date-time */
	ferrySailingDate?: string | null;
	goods?: ViewGoodsResponse[] | null;
	/** @format int32 */
	id: number;
	isNew: boolean;
	/** @format int32 */
	jobId?: number | null;
	jobType: ViewBaseResponse;
	/** @format int32 */
	linkLegId?: number | null;
	/** @format int32 */
	loadId?: number | null;
	mrn?: string | null;
	notes?: string | null;
	/** @format int32 */
	parentLegId?: number | null;
	purchaseOrderNumber?: string | null;
	status: LegStatusType;
	/** @format date-time */
	storageEndDate?: string | null;
	storageLocation?: string | null;
	/** @format date-time */
	storageStartDate?: string | null;
	subcontractor: ViewContactBaseResponse;
	supplierInvoice: SupplierInvoiceType;
	/** @format date-time */
	supplierInvoiceDate?: string | null;
	supplierInvoiceNumber?: string | null;
	trailer: ViewBaseResponse;
	trailerType: ViewBaseResponse;
	transferBusinessUnitId?: string | null;
	truck: ViewBaseResponse;
	truckType: ViewBaseResponse;
	type: LegType;
	ucr?: string | null;
	uniqueId?: string | null;
}

export interface ViewLoadResponse {
	actions: LoadActions;
	assignedTo?: string | null;
	assignedToName?: string | null;
	/** @format double */
	cost?: number | null;
	currencyCode?: string | null;
	/** @format int32 */
	currencyId?: number | null;
	driverName?: string | null;
	/** @format date-time */
	endDate?: string | null;
	/** @format int32 */
	endLocationId?: number | null;
	endLocationName?: string | null;
	/** @format int32 */
	id: number;
	/** @format int32 */
	loadTypeId: number;
	loadTypeName?: string | null;
	notes?: string | null;
	/** @format double */
	profit?: number | null;
	/** @format double */
	revenue?: number | null;
	/** @format double */
	sharedCost?: number | null;
	/** @format date-time */
	startDate?: string | null;
	/** @format int32 */
	startLocationId: number;
	startLocationName?: string | null;
	status: LoadStatusType;
	/** @format int32 */
	subcontractorId?: number | null;
	subcontractorName?: string | null;
	trailerName?: string | null;
	truckName?: string | null;
	uniqueId: string;
}

export interface ViewLoadResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewLoadResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewLoadTypeResponse {
	/** @format int32 */
	id: number;
	name: string;
	/** @format int32 */
	order: number;
	status: Status;
}

export interface ViewLoadTypeResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewLoadTypeResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewLocationBaseResponse {
	address?: string | null;
	/** @format int32 */
	id: number;
	name: string;
}

export interface ViewLocationResponse {
	address: string;
	customer: ViewBaseResponse;
	/** @format int32 */
	id: number;
	/** @format double */
	latitude?: number | null;
	/** @format double */
	longitude?: number | null;
	name: string;
	notes?: string | null;
	status: Status;
}

export interface ViewLocationResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewLocationResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewQualificationResponse {
	constraints: ViewBaseResponse[];
	/** @format date-time */
	dateTaken: string;
	documents: ViewAttachmentResponse[];
	driver: ViewBaseResponse;
	/** @format date-time */
	expirationDate: string;
	/** @format int32 */
	id: number;
	name: string;
}

export interface ViewQualificationResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewQualificationResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewRoleResponse {
	id: string;
	name: string;
}

export interface ViewSearchResponse {
	customerName: string | null;
	driverName: string | null;
	/** @format date-time */
	endDate: string | null;
	endLocationName: string | null;
	/** @format int32 */
	id: number;
	/** @format int32 */
	jobId: number | null;
	/** @format int32 */
	loadId: number | null;
	objectType: ObjectType;
	/** @format date-time */
	startDate: string | null;
	startLocationName: string | null;
	/** @format int32 */
	status: number;
	subcontractorName: string | null;
	/** @format int32 */
	type: number;
	typeName: string | null;
	uniqueId: string | null;
}

export interface ViewSubcontractorResponse {
	constraints: ViewBaseResponse[];
	emails: string[];
	/** @format int32 */
	id: number;
	name: string;
	notes?: string | null;
	phones: string[];
	trailerTypes: ViewBaseResponse[];
	truckRegistrationNumbers: string[];
	truckTypes: ViewBaseResponse[];
}

export interface ViewSubcontractorResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewSubcontractorResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewTrailerResponse {
	chassisNo?: string | null;
	constraints: ViewBaseResponse[];
	documents: ViewAttachmentResponse[];
	europeanRegistrationNumber?: string | null;
	fleetNumber?: string | null;
	/** @format int32 */
	id: number;
	ministryNumber?: string | null;
	name: string;
	/** @format date-time */
	nextInspectionDate?: string | null;
	/** @format date-time */
	nextMOTDate?: string | null;
	notes?: string | null;
	status: Status;
	subcontractor: ViewBaseResponse;
	trailerType: ViewBaseResponse;
}

export interface ViewTrailerResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewTrailerResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewTrailerTypeResponse {
	/** @format int32 */
	id: number;
	name: string;
	status: Status;
}

export interface ViewTrailerTypeResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewTrailerTypeResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewTruckResponse {
	constraintIds?: number[] | null;
	constraints: ViewBaseResponse[];
	documents: ViewAttachmentResponse[];
	/** @format int32 */
	id: number;
	name: string;
	/** @format date-time */
	nextInspectionTime: string;
	/** @format date-time */
	nextMOTDate: string;
	notes?: string | null;
	registration: string;
	status: Status;
	subcontractor: ViewBaseResponse;
	/** @format int32 */
	subcontractorId?: number | null;
	trailer: ViewBaseResponse;
	/** @format int32 */
	trailerId?: number | null;
	truckType: ViewBaseResponse;
	/** @format int32 */
	truckTypeId: number;
}

export interface ViewTruckResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewTruckResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewTruckTypeResponse {
	/** @format int32 */
	id: number;
	isArticulated: boolean;
	name: string;
	status: Status;
}

export interface ViewTruckTypeResponseDataSourceResultGeneric {
	aggregateResults: any[];
	data: ViewTruckTypeResponse[];
	errors: any;
	/** @format int32 */
	total: number;
}

export interface ViewUserResponse {
	businessUnitIds: number[];
	/** @format int32 */
	driverId?: number | null;
	email: string;
	firstName: string;
	fullName: string;
	id: string;
	lastName: string;
	roles: ViewRoleResponse[];
	status: UserStatus;
}

export namespace Attachment {
	/**
	 * No description
	 * @tags Attachment
	 * @name AttachmentCreate
	 * @summary Adds an attachment(s) to an existing entity
	 * @request POST:/job-gateway/Attachment/{entityId}
	 * @secure
	 * @response `200` `(ViewAttachmentResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace AttachmentCreate {
		export type RequestParams = {
			/** @format int32 */
			entityId: number;
		};
		export type RequestQuery = {
			isPod?: boolean;
			usage?: AttachmentUsageType;
		};
		export type RequestBody = AttachmentCreatePayload;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewAttachmentResponse[];
	}

	/**
	 * No description
	 * @tags Attachment
	 * @name AttachmentDelete
	 * @summary Deletes an attachment from an existing entity
	 * @request DELETE:/job-gateway/Attachment/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace AttachmentDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Attachment
	 * @name AttachmentDetail
	 * @summary Gets an attachment URI
	 * @request GET:/job-gateway/Attachment/{id}
	 * @secure
	 * @response `200` `string` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace AttachmentDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = string;
	}

	/**
	 * No description
	 * @tags Attachment
	 * @name AttachmentListDetail
	 * @summary Lists all attachments for an entity
	 * @request GET:/job-gateway/Attachment/{entityId}/list
	 * @secure
	 * @response `200` `(ViewAttachmentResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace AttachmentListDetail {
		export type RequestParams = {
			/** @format int32 */
			entityId: number;
		};
		export type RequestQuery = {
			isInvoice?: boolean;
			isPod?: boolean;
			usage?: AttachmentUsageType;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewAttachmentResponse[];
	}
}

export namespace Bff {
	/**
	 * No description
	 * @tags BFF
	 * @name BffClosestDriversRecommendationCreate
	 * @request POST:/job-gateway/BFF/closestDriversRecommendation
	 * @secure
	 * @response `200` `(ClosestDriversRecommendation)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffClosestDriversRecommendationCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = GetClosestDriversRecommendationRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ClosestDriversRecommendation[];
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffFleetLookupCreate
	 * @request POST:/job-gateway/BFF/fleetLookup
	 * @secure
	 * @response `200` `FleetLookup` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffFleetLookupCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = FleetLookup;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = FleetLookup;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffGetMultiEditsCreate
	 * @request POST:/job-gateway/BFF/getMultiEdits
	 * @secure
	 * @response `200` `(MultiEdit)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffGetMultiEditsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = GetMultiEditInput;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = MultiEdit[];
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffGoodFormDataCreate
	 * @request POST:/job-gateway/BFF/goodFormData
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffGoodFormDataCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = GoodFormDataResponse;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffGoodFormDataDetail
	 * @request GET:/job-gateway/BFF/goodFormData/{id}
	 * @secure
	 * @response `200` `GoodFormDataResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffGoodFormDataDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = GoodFormDataResponse;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffJobDetailsDetail
	 * @request GET:/job-gateway/BFF/jobDetails/{id}
	 * @secure
	 * @response `200` `JobDetailsResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffJobDetailsDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = JobDetailsResponse;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffLegFormDataCreate
	 * @request POST:/job-gateway/BFF/legFormData
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffLegFormDataCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = LegFormDataResponse;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffLegFormDataDetail
	 * @request GET:/job-gateway/BFF/legFormData/{id}
	 * @secure
	 * @response `200` `LegFormDataResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffLegFormDataDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = LegFormDataResponse;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffLegFormDataGoodsDetail
	 * @request GET:/job-gateway/BFF/legFormDataGoods/{id}
	 * @secure
	 * @response `200` `LegFormDataGoodsResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffLegFormDataGoodsDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = LegFormDataGoodsResponse;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffLegNoteDataCreate
	 * @request POST:/job-gateway/BFF/legNoteData
	 * @secure
	 * @response `200` `(LegNoteData)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffLegNoteDataCreate {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @format int32 */
			legId?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = LegNoteData[];
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffLoadDetailsDetail
	 * @request GET:/job-gateway/BFF/loadDetails/{id}
	 * @secure
	 * @response `200` `LoadDetailsResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffLoadDetailsDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = LoadDetailsResponse;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffLookupSettingsKeysList
	 * @request GET:/job-gateway/BFF/lookupSettingsKeys
	 * @secure
	 * @response `200` `(string)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffLookupSettingsKeysList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = string[];
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffMapSettingsList
	 * @request GET:/job-gateway/BFF/mapSettings
	 * @secure
	 * @response `200` `MapSettings` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffMapSettingsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = MapSettings;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffRandomDriversRecommendationCreate
	 * @request POST:/job-gateway/BFF/randomDriversRecommendation
	 * @secure
	 * @response `200` `(RandomDriversRecommendation)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffRandomDriversRecommendationCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = RandomDriversRecommendation[];
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffRecommendationFeedbackCreate
	 * @request POST:/job-gateway/BFF/recommendationFeedback
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffRecommendationFeedbackCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = RecommendationFeedbackInput;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffRecommendationSettingsList
	 * @request GET:/job-gateway/BFF/recommendationSettings
	 * @secure
	 * @response `200` `(string)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffRecommendationSettingsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = string[];
	}

	/**
	 * No description
	 * @tags BFF
	 * @name BffSaveMultiEditCreate
	 * @request POST:/job-gateway/BFF/saveMultiEdit
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace BffSaveMultiEditCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = BffSaveMultiEditCreatePayload;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}
}

export namespace Checklist {
	/**
	 * No description
	 * @tags Checklist
	 * @name ChecklistCreate
	 * @summary Creates an entity
	 * @request POST:/job-gateway/Checklist
	 * @secure
	 * @response `200` `ViewChecklistResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace ChecklistCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateChecklistRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewChecklistResponse;
	}

	/**
	 * No description
	 * @tags Checklist
	 * @name ChecklistDelete
	 * @summary Deletes an entity by its ID
	 * @request DELETE:/job-gateway/Checklist/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace ChecklistDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Checklist
	 * @name ChecklistDetail
	 * @summary Gets an entity by its ID
	 * @request GET:/job-gateway/Checklist/{id}
	 * @secure
	 * @response `200` `ViewChecklistResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace ChecklistDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewChecklistResponse;
	}

	/**
	 * No description
	 * @tags Checklist
	 * @name ChecklistList
	 * @summary Gets all Checklists
	 * @request GET:/job-gateway/Checklist
	 * @deprecated
	 * @secure
	 * @response `200` `ViewChecklistResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace ChecklistList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewChecklistResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Checklist
	 * @name ChecklistLookupList
	 * @summary Gets all Checklists (lookup)
	 * @request GET:/job-gateway/Checklist/lookup
	 * @secure
	 * @response `200` `(ViewChecklistResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace ChecklistLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewChecklistResponse[];
	}

	/**
	 * No description
	 * @tags Checklist
	 * @name ChecklistUpdate
	 * @summary Updates a given entity
	 * @request PUT:/job-gateway/Checklist
	 * @secure
	 * @response `200` `ViewChecklistResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace ChecklistUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateChecklistRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewChecklistResponse;
	}
}

export namespace Constraint {
	/**
	 * No description
	 * @tags Constraint
	 * @name ConstraintCreate
	 * @summary Creates a constraint
	 * @request POST:/job-gateway/Constraint
	 * @secure
	 * @response `200` `ViewConstraintResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace ConstraintCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateConstraintRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewConstraintResponse;
	}

	/**
	 * No description
	 * @tags Constraint
	 * @name ConstraintDelete
	 * @summary Deletes constraint by its ID
	 * @request DELETE:/job-gateway/Constraint/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace ConstraintDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Constraint
	 * @name ConstraintDetail
	 * @summary Gets a constraint by its ID
	 * @request GET:/job-gateway/Constraint/{id}
	 * @secure
	 * @response `200` `(ViewConstraintResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace ConstraintDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewConstraintResponse[];
	}

	/**
	 * No description
	 * @tags Constraint
	 * @name ConstraintList
	 * @summary Gets all constraints
	 * @request GET:/job-gateway/Constraint
	 * @deprecated
	 * @secure
	 * @response `200` `ViewConstraintResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace ConstraintList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewConstraintResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Constraint
	 * @name ConstraintLookupList
	 * @summary Gets all constraints (lookup)
	 * @request GET:/job-gateway/Constraint/lookup
	 * @secure
	 * @response `200` `(ViewConstraintResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace ConstraintLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewConstraintResponse[];
	}

	/**
	 * No description
	 * @tags Constraint
	 * @name ConstraintUpdate
	 * @summary Updates a given constraint
	 * @request PUT:/job-gateway/Constraint
	 * @secure
	 * @response `200` `ViewConstraintResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace ConstraintUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateConstraintRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewConstraintResponse;
	}
}

export namespace Currency {
	/**
	 * No description
	 * @tags Currency
	 * @name CurrencyBusinessUnitList
	 * @summary Gets currency for current business unit
	 * @request GET:/job-gateway/Currency/businessUnit
	 * @secure
	 * @response `200` `ViewCurrencyResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CurrencyBusinessUnitList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyResponse;
	}

	/**
	 * No description
	 * @tags Currency
	 * @name CurrencyCreate
	 * @summary Creates an entity
	 * @request POST:/job-gateway/Currency
	 * @secure
	 * @response `200` `ViewCurrencyResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CurrencyCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateCurrencyRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyResponse;
	}

	/**
	 * No description
	 * @tags Currency
	 * @name CurrencyDelete
	 * @summary Deletes an entity by its ID
	 * @request DELETE:/job-gateway/Currency/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CurrencyDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Currency
	 * @name CurrencyDetail
	 * @summary Gets an entity by its ID
	 * @request GET:/job-gateway/Currency/{id}
	 * @secure
	 * @response `200` `ViewCurrencyResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CurrencyDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyResponse;
	}

	/**
	 * No description
	 * @tags Currency
	 * @name CurrencyList
	 * @summary Gets all currencies
	 * @request GET:/job-gateway/Currency
	 * @deprecated
	 * @secure
	 * @response `200` `ViewCurrencyResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CurrencyList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Currency
	 * @name CurrencyLookupList
	 * @summary Gets all currencies (lookup)
	 * @request GET:/job-gateway/Currency/lookup
	 * @secure
	 * @response `200` `(ViewCurrencyResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CurrencyLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyResponse[];
	}

	/**
	 * No description
	 * @tags Currency
	 * @name CurrencyUpdate
	 * @summary Updates a given entity
	 * @request PUT:/job-gateway/Currency
	 * @secure
	 * @response `200` `ViewCurrencyResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CurrencyUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateCurrencyRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyResponse;
	}
}

export namespace CurrencyExchangeRate {
	/**
	 * No description
	 * @tags CurrencyExchangeRate
	 * @name CurrencyExchangeRateCreate
	 * @summary Creates an entity
	 * @request POST:/job-gateway/CurrencyExchangeRate
	 * @secure
	 * @response `200` `ViewCurrencyExchangeRateResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CurrencyExchangeRateCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateCurrencyExchangeRateRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyExchangeRateResponse;
	}

	/**
	 * No description
	 * @tags CurrencyExchangeRate
	 * @name CurrencyExchangeRateDelete
	 * @summary Deletes an entity by its ID
	 * @request DELETE:/job-gateway/CurrencyExchangeRate/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CurrencyExchangeRateDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags CurrencyExchangeRate
	 * @name CurrencyExchangeRateDetail
	 * @summary Gets an entity by its ID
	 * @request GET:/job-gateway/CurrencyExchangeRate/{id}
	 * @secure
	 * @response `200` `ViewCurrencyExchangeRateResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CurrencyExchangeRateDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyExchangeRateResponse;
	}

	/**
	 * No description
	 * @tags CurrencyExchangeRate
	 * @name CurrencyExchangeRateList
	 * @summary Gets all currency exchange rates
	 * @request GET:/job-gateway/CurrencyExchangeRate
	 * @deprecated
	 * @secure
	 * @response `200` `ViewCurrencyExchangeRateResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CurrencyExchangeRateList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody =
			ViewCurrencyExchangeRateResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags CurrencyExchangeRate
	 * @name CurrencyExchangeRateUpdate
	 * @summary Updates a given entity
	 * @request PUT:/job-gateway/CurrencyExchangeRate
	 * @secure
	 * @response `200` `ViewCurrencyExchangeRateResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CurrencyExchangeRateUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateCurrencyExchangeRateRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCurrencyExchangeRateResponse;
	}
}

export namespace Customer {
	/**
	 * No description
	 * @tags Customer
	 * @name CustomerBulkCreate
	 * @summary Creates bulk entities
	 * @request POST:/job-gateway/Customer/bulk
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CustomerBulkCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CustomerBulkCreatePayload;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Customer
	 * @name CustomerCreate
	 * @summary Creates an entity
	 * @request POST:/job-gateway/Customer
	 * @secure
	 * @response `200` `ViewCustomerResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CustomerCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateCustomerRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCustomerResponse;
	}

	/**
	 * No description
	 * @tags Customer
	 * @name CustomerDelete
	 * @summary Deletes an entity by its ID
	 * @request DELETE:/job-gateway/Customer/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CustomerDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Customer
	 * @name CustomerDetail
	 * @summary Gets an entity by its ID
	 * @request GET:/job-gateway/Customer/{id}
	 * @secure
	 * @response `200` `ViewCustomerResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CustomerDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCustomerResponse;
	}

	/**
	 * No description
	 * @tags Customer
	 * @name CustomerList
	 * @summary Gets all customers
	 * @request GET:/job-gateway/Customer
	 * @deprecated
	 * @secure
	 * @response `200` `ViewCustomerResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CustomerList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCustomerResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Customer
	 * @name CustomerLookupList
	 * @summary Gets all customers (lookup)
	 * @request GET:/job-gateway/Customer/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace CustomerLookupList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags Customer
	 * @name CustomerSyncList
	 * @request GET:/job-gateway/Customer/sync
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CustomerSyncList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Customer
	 * @name CustomerUpdate
	 * @summary Updates a given entity
	 * @request PUT:/job-gateway/Customer
	 * @secure
	 * @response `200` `ViewCustomerResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace CustomerUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateCustomerRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewCustomerResponse;
	}
}

export namespace Driver {
	/**
	 * No description
	 * @tags Driver
	 * @name DriverAssignedLegDetail
	 * @summary Gets last assigned leg to the driver
	 * @request GET:/job-gateway/Driver/{id}/assigned-leg
	 * @secure
	 * @response `200` `ViewDriverLastAssignedLegResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace DriverAssignedLegDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDriverLastAssignedLegResponse;
	}

	/**
	 * No description
	 * @tags Driver
	 * @name DriverCreate
	 * @summary Creates a driver
	 * @request POST:/job-gateway/Driver
	 * @secure
	 * @response `200` `ViewDriverResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace DriverCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateDriverRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDriverResponse;
	}

	/**
	 * No description
	 * @tags Driver
	 * @name DriverDelete
	 * @summary Deletes a driver
	 * @request DELETE:/job-gateway/Driver/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace DriverDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Driver
	 * @name DriverDetail
	 * @summary Gets an driver by its ID
	 * @request GET:/job-gateway/Driver/{id}
	 * @secure
	 * @response `200` `ViewDriverResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace DriverDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDriverResponse;
	}

	/**
	 * No description
	 * @tags Driver
	 * @name DriverList
	 * @summary Get all drivers
	 * @request GET:/job-gateway/Driver
	 * @deprecated
	 * @secure
	 * @response `200` `ViewDriverResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace DriverList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDriverResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Driver
	 * @name DriverLookupList
	 * @summary Get all drivers (lookup)
	 * @request GET:/job-gateway/Driver/lookup
	 * @secure
	 * @response `200` `(ViewContactBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace DriverLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewContactBaseResponse[];
	}

	/**
	 * No description
	 * @tags Driver
	 * @name DriverUpdate
	 * @summary Updates a driver
	 * @request PUT:/job-gateway/Driver
	 * @secure
	 * @response `200` `ViewDriverResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace DriverUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateDriverRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDriverResponse;
	}
}

export namespace Fleet {
	/**
	 * No description
	 * @tags Fleet
	 * @name FleetFindList
	 * @summary Find fleet
	 * @request GET:/job-gateway/Fleet/find
	 * @secure
	 * @response `200` `FleetFindResonse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace FleetFindList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @format int32 */
			DriverId?: number;
			/** @format int32 */
			SubcontractorId?: number;
			/** @format int32 */
			TrailerId?: number;
			/** @format int32 */
			TruckId?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = FleetFindResonse;
	}
}

export namespace Goods {
	/**
	 * No description
	 * @tags Goods
	 * @name GoodsCreate
	 * @summary Creates an entity
	 * @request POST:/job-gateway/Goods
	 * @secure
	 * @response `200` `ViewGoodsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GoodsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateGoodsRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewGoodsResponse;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsDelete
	 * @summary Deletes an entity by its ID
	 * @request DELETE:/job-gateway/Goods/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GoodsDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsDetail
	 * @summary Gets an entity by its ID
	 * @request GET:/job-gateway/Goods/{id}
	 * @secure
	 * @response `200` `ViewGoodsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GoodsDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewGoodsResponse;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsJobDetail
	 * @summary Gets all goods by job ID
	 * @request GET:/job-gateway/Goods/job/{id}
	 * @deprecated
	 * @secure
	 * @response `200` `ViewGoodsResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GoodsJobDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewGoodsResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsLoadCreate
	 * @summary Add goods for load
	 * @request POST:/job-gateway/Goods/load/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GoodsLoadCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = CreateGoodsForLoadRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsLoadDelete
	 * @summary Deletes an entity from a load by its ID
	 * @request DELETE:/job-gateway/Goods/{id}/load/{loadId}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GoodsLoadDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
			/** @format int32 */
			loadId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsLoadDetail
	 * @summary Gets all goods by load ID
	 * @request GET:/job-gateway/Goods/load/{id}
	 * @deprecated
	 * @secure
	 * @response `200` `ViewGoodsResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GoodsLoadDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewGoodsResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsLoadList
	 * @summary Gets all goods that can be taken into a load
	 * @request GET:/job-gateway/Goods/load
	 * @deprecated
	 * @secure
	 * @response `200` `ViewGoodsForLoadRequestDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GoodsLoadList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @format int32 */
			loadId?: number;
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewGoodsForLoadRequestDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsLookupDetail
	 * @summary Get all goods for a given jobId (lookup)
	 * @request GET:/job-gateway/Goods/lookup/{jobId}
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GoodsLookupDetail {
		export type RequestParams = {
			/** @format int32 */
			jobId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsReportCargoLabelDetail
	 * @summary Returns Cargo label PDF
	 * @request GET:/job-gateway/Goods/report/{goodId}/cargoLabel
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GoodsReportCargoLabelDetail {
		export type RequestParams = {
			/** @format int32 */
			goodId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsReportCmrDetail
	 * @summary Returns CMR PDF
	 * @request GET:/job-gateway/Goods/report/{goodId}/cmr
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GoodsReportCmrDetail {
		export type RequestParams = {
			/** @format int32 */
			goodId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Goods
	 * @name GoodsUpdate
	 * @summary Updates a given entity
	 * @request PUT:/job-gateway/Goods
	 * @secure
	 * @response `200` `ViewGoodsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GoodsUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateGoodsRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewGoodsResponse;
	}
}

export namespace Invoice {
	/**
	 * No description
	 * @tags Invoice
	 * @name InvoiceList
	 * @summary Returns a list of filtered invoiced
	 * @request GET:/job-gateway/Invoice
	 * @deprecated
	 * @secure
	 * @response `200` `ViewInvoiceResponseDataSourceResultGeneric` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace InvoiceList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewInvoiceResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Invoice
	 * @name InvoiceReportInvoiceDetail
	 * @summary Returns invoice PDF Report
	 * @request GET:/job-gateway/Invoice/report/{id}/invoice
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace InvoiceReportInvoiceDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Invoice
	 * @name InvoiceScheduleCreate
	 * @summary Schedule invoice creation
	 * @request POST:/job-gateway/Invoice/schedule
	 * @secure
	 * @response `200` `(InvoiceGenerationResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace InvoiceScheduleCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateInvoiceBulkRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = InvoiceGenerationResponse[];
	}
}

export namespace Job {
	/**
	 * No description
	 * @tags Job
	 * @name DeleteJob
	 * @summary Deletes a job by its ID
	 * @request DELETE:/job-gateway/Job/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace DeleteJob {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name GetJob
	 * @summary Returns a list of filtered jobs
	 * @request GET:/job-gateway/Job
	 * @deprecated
	 * @secure
	 * @response `200` `ViewJobResponseDataSourceResultGeneric` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GetJob {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewJobResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Job
	 * @name GetJob2
	 * @summary Gets a job by its ID
	 * @request GET:/job-gateway/Job/{id}
	 * @originalName getJob
	 * @duplicate
	 * @secure
	 * @response `200` `DetailedViewJobResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GetJob2 {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewJobResponse;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobChangeAllStatusCreate
	 * @summary Changes multiple jobs status
	 * @request POST:/job-gateway/Job/{ids}/change-all-status/{status}
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobChangeAllStatusCreate {
		export type RequestParams = {
			ids: string;
			status: JobStatusType;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobChangeStatusCancelCreate
	 * @summary Changes job status to Cancelled
	 * @request POST:/job-gateway/Job/{id}/change-status/cancel
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobChangeStatusCancelCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobChangeStatusCreate
	 * @summary Changes job status
	 * @request POST:/job-gateway/Job/{id}/change-status/{status}
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobChangeStatusCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
			status: JobStatusType;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobChangeStatusPauseCreate
	 * @summary Changes job status to Paused
	 * @request POST:/job-gateway/Job/{id}/change-status/pause
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobChangeStatusPauseCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobChangeStatusResumeCreate
	 * @summary Changes job status to Planned
	 * @request POST:/job-gateway/Job/{id}/change-status/resume
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobChangeStatusResumeCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobChecklistsCreate
	 * @summary Saves checklists item state
	 * @request POST:/job-gateway/Job/{id}/checklists
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace JobChecklistsCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = JobChecklistsCreatePayload;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobChecklistsDetail
	 * @summary Gets checklists items by job ID
	 * @request GET:/job-gateway/Job/{id}/checklists
	 * @secure
	 * @response `200` `(ViewJobChecklistSettingModel)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace JobChecklistsDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewJobChecklistSettingModel[];
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobDuplicateCreate
	 * @summary Creates a job
	 * @request POST:/job-gateway/Job/duplicate/{jobId}
	 * @secure
	 * @response `200` `DetailedViewJobResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace JobDuplicateCreate {
		export type RequestParams = {
			/** @format int32 */
			jobId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = CreateJobRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewJobResponse;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobForceCompleteCreate
	 * @summary Force changes job status to Completed
	 * @request POST:/job-gateway/Job/{id}/force-complete
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobForceCompleteCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobIsNewUpdate
	 * @summary Update job IsNew flag
	 * @request PUT:/job-gateway/Job/is-new
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace JobIsNewUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateJobIsNewRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobPartialUpdate
	 * @summary Partial updates a given job
	 * @request PUT:/job-gateway/Job/partial
	 * @secure
	 * @response `200` `DetailedViewJobResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobPartialUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = PartialUpdateJobRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewJobResponse;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobRecalculateUpdate
	 * @summary Recalculate job prices
	 * @request PUT:/job-gateway/Job/{id}/recalculate
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobRecalculateUpdate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobReportFileFrontDetail
	 * @summary Returns file front PDF Report
	 * @request GET:/job-gateway/Job/report/{id}/fileFront
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobReportFileFrontDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Job
	 * @name JobV2List
	 * @summary Returns a list of filtered jobs
	 * @request GET:/job-gateway/Job/v2
	 * @secure
	 * @response `200` `(ViewJobResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace JobV2List {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewJobResponse[];
	}

	/**
	 * No description
	 * @tags Job
	 * @name PostJob
	 * @summary Creates a job
	 * @request POST:/job-gateway/Job
	 * @secure
	 * @response `200` `DetailedViewJobResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace PostJob {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateJobRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewJobResponse;
	}

	/**
	 * No description
	 * @tags Job
	 * @name PutJob
	 * @summary Updates a given job
	 * @request PUT:/job-gateway/Job
	 * @secure
	 * @response `200` `DetailedViewJobResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace PutJob {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateJobRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewJobResponse;
	}
}

export namespace JobType {
	/**
	 * No description
	 * @tags JobType
	 * @name JobTypeCreate
	 * @summary Creates a job type
	 * @request POST:/job-gateway/JobType
	 * @secure
	 * @response `200` `ViewJobTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobTypeCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateJobTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewJobTypeResponse;
	}

	/**
	 * No description
	 * @tags JobType
	 * @name JobTypeDelete
	 * @summary Deletes a job type
	 * @request DELETE:/job-gateway/JobType/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobTypeDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags JobType
	 * @name JobTypeDetail
	 * @summary Gets a job type by its ID
	 * @request GET:/job-gateway/JobType/{id}
	 * @secure
	 * @response `200` `ViewJobTypeResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobTypeDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewJobTypeResponse;
	}

	/**
	 * No description
	 * @tags JobType
	 * @name JobTypeList
	 * @summary Get all job types
	 * @request GET:/job-gateway/JobType
	 * @deprecated
	 * @secure
	 * @response `200` `ViewJobTypeResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace JobTypeList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewJobTypeResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags JobType
	 * @name JobTypeLookupList
	 * @summary Get all job types (lookup)
	 * @request GET:/job-gateway/JobType/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace JobTypeLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags JobType
	 * @name JobTypeUpdate
	 * @summary Updates a job type
	 * @request PUT:/job-gateway/JobType
	 * @secure
	 * @response `200` `ViewJobTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace JobTypeUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateJobTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewJobTypeResponse;
	}
}

export namespace Leg {
	/**
	 * No description
	 * @tags Leg
	 * @name DeleteLeg
	 * @summary Deletes a leg by its ID
	 * @request DELETE:/job-gateway/Leg/{legId}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace DeleteLeg {
		export type RequestParams = {
			/** @format int32 */
			legId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name GetLeg
	 * @summary Returns a list of filtered legs
	 * @request GET:/job-gateway/Leg
	 * @deprecated
	 * @secure
	 * @response `200` `ViewDisplayLegsResponseDataSourceResultGeneric` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace GetLeg {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDisplayLegsResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name GetLeg2
	 * @summary Gets a leg by its ID
	 * @request GET:/job-gateway/Leg/{legId}
	 * @originalName getLeg
	 * @duplicate
	 * @secure
	 * @response `200` `ViewLegsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace GetLeg2 {
		export type RequestParams = {
			/** @format int32 */
			legId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegChangeStatusCreate
	 * @summary Changes leg status
	 * @request POST:/job-gateway/Leg/{legId}/change-status
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegChangeStatusCreate {
		export type RequestParams = {
			/** @format int32 */
			legId: number;
		};
		export type RequestQuery = {
			status?: LegStatusType;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegForDriverList
	 * @request GET:/job-gateway/Leg/forDriver
	 * @secure
	 * @response `200` `(ViewLegsForDriverResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegForDriverList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsForDriverResponse[];
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegIsNewUpdate
	 * @summary Update leg IsNew flag
	 * @request PUT:/job-gateway/Leg/is-new
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegIsNewUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateLegsIsNewRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegJobDetail
	 * @summary Gets all legs by job ID
	 * @request GET:/job-gateway/Leg/job/{jobId}
	 * @secure
	 * @response `200` `(ViewDisplayLegsResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegJobDetail {
		export type RequestParams = {
			/** @format int32 */
			jobId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDisplayLegsResponse[];
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegJobGroupedDetail
	 * @summary Gets alternative view grouped legs by job ID
	 * @request GET:/job-gateway/Leg/job/{jobId}/grouped
	 * @secure
	 * @response `200` `GroupViewLegsResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegJobGroupedDetail {
		export type RequestParams = {
			/** @format int32 */
			jobId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = GroupViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegLoadDetail
	 * @summary Gets all legs by load ID
	 * @request GET:/job-gateway/Leg/load/{loadId}
	 * @secure
	 * @response `200` `(ViewDisplayLegsResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegLoadDetail {
		export type RequestParams = {
			/** @format int32 */
			loadId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDisplayLegsResponse[];
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegLoadGroupedDetail
	 * @summary Gets alternative view grouped legs by load ID
	 * @request GET:/job-gateway/Leg/load/{loadId}/grouped
	 * @secure
	 * @response `200` `GroupViewLegsResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegLoadGroupedDetail {
		export type RequestParams = {
			/** @format int32 */
			loadId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = GroupViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegReportCollectionNoteDetail
	 * @summary Returns collection note PDF
	 * @request GET:/job-gateway/Leg/report/{legId}/collectionNote/{goodsId}
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LegReportCollectionNoteDetail {
		export type RequestParams = {
			/** @format int32 */
			goodsId: number;
			/** @format int32 */
			legId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegReportDeliveryTicketDetail
	 * @summary Returns collection note PDF
	 * @request GET:/job-gateway/Leg/report/{legId}/deliveryTicket/{goodsId}
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LegReportDeliveryTicketDetail {
		export type RequestParams = {
			/** @format int32 */
			goodsId: number;
			/** @format int32 */
			legId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegReportSubcontractorOrderDetail
	 * @summary Returns subcontractor order PDF
	 * @request GET:/job-gateway/Leg/report/{legId}/subcontractorOrder
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LegReportSubcontractorOrderDetail {
		export type RequestParams = {
			/** @format int32 */
			legId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegStatusesList
	 * @summary Retrieves leg statuses
	 * @request GET:/job-gateway/Leg/statuses
	 * @secure
	 * @response `200` `(ViewLegStatusResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegStatusesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegStatusResponse[];
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegSupplierInvoiceNumberUpdate
	 * @summary Partial leg update
	 * @request PUT:/job-gateway/Leg/{legId}/supplier-invoice-number
	 * @secure
	 * @response `200` `ViewLegsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LegSupplierInvoiceNumberUpdate {
		export type RequestParams = {
			/** @format int32 */
			legId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = PartialUpdateLegsRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegSupplierInvoiceNumberUpdate2
	 * @summary Updates a given leg with Supplier Invoice Number
	 * @request PUT:/job-gateway/Leg/{legId}/supplier-invoice-number/{supplierInvoiceNumber}/{supplierInvoiceDate}
	 * @deprecated
	 * @originalName legSupplierInvoiceNumberUpdate
	 * @duplicate
	 * @secure
	 * @response `200` `ViewLegsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LegSupplierInvoiceNumberUpdate2 {
		export type RequestParams = {
			/** @format int32 */
			legId: number;
			/** @format date-time */
			supplierInvoiceDate: string;
			supplierInvoiceNumber: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegV2Create
	 * @summary Creates an leg v2
	 * @request POST:/job-gateway/Leg/v2
	 * @secure
	 * @response `200` `ViewLegsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegV2Create {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateLegsRequestV2;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegV2List
	 * @summary Returns a list of filtered legs
	 * @request GET:/job-gateway/Leg/v2
	 * @secure
	 * @response `200` `(ViewDisplayLegsResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegV2List {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewDisplayLegsResponse[];
	}

	/**
	 * No description
	 * @tags Leg
	 * @name LegV2Update
	 * @summary Updates a given leg v2
	 * @request PUT:/job-gateway/Leg/v2
	 * @secure
	 * @response `200` `ViewLegsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LegV2Update {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateLegsRequestV2;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name PostLeg
	 * @summary Creates an leg
	 * @request POST:/job-gateway/Leg
	 * @secure
	 * @response `200` `ViewLegsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace PostLeg {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateLegsRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsResponse;
	}

	/**
	 * No description
	 * @tags Leg
	 * @name PutLeg
	 * @summary Updates a given leg
	 * @request PUT:/job-gateway/Leg
	 * @secure
	 * @response `200` `ViewLegsResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace PutLeg {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateLegsRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegsResponse;
	}
}

export namespace LegStatus {
	/**
	 * No description
	 * @tags LegStatus
	 * @name LegStatusList
	 * @summary Gets all leg statuses
	 * @request GET:/job-gateway/LegStatus
	 * @secure
	 * @response `200` `(ViewLegStatusResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegStatusList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLegStatusResponse[];
	}

	/**
	 * No description
	 * @tags LegStatus
	 * @name LegStatusLookupList
	 * @summary Gets all leg statuses (lookup)
	 * @request GET:/job-gateway/LegStatus/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LegStatusLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}
}

export namespace Load {
	/**
	 * No description
	 * @tags Load
	 * @name LoadChangeStatusCancelCreate
	 * @summary Changes load status to Cancelled
	 * @request POST:/job-gateway/Load/{id}/change-status/cancel
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadChangeStatusCancelCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadChangeStatusCreate
	 * @summary Change load status
	 * @request POST:/job-gateway/Load/{id}/change-status/{status}
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadChangeStatusCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
			status: LoadStatusType;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadChangeStatusPauseCreate
	 * @summary Changes load status to Paused
	 * @request POST:/job-gateway/Load/{id}/change-status/pause
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadChangeStatusPauseCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadChangeStatusResumeCreate
	 * @summary Changes load status to Planned
	 * @request POST:/job-gateway/Load/{id}/change-status/resume
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadChangeStatusResumeCreate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadClearCustomsAttachmentsDetail
	 * @summary Gets clear customs attachments by load ID
	 * @request GET:/job-gateway/Load/{id}/clearCustomsAttachments
	 * @secure
	 * @response `200` `(ViewAttachmentResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadClearCustomsAttachmentsDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewAttachmentResponse[];
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadCreate
	 * @summary Creates a load
	 * @request POST:/job-gateway/Load
	 * @secure
	 * @response `200` `DetailedViewLoadResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LoadCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateLoadRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewLoadResponse;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadDelete
	 * @summary Deletes a load by its ID
	 * @request DELETE:/job-gateway/Load/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadDetail
	 * @summary Gets a load by its ID
	 * @request GET:/job-gateway/Load/{id}
	 * @secure
	 * @response `200` `DetailedViewLoadResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewLoadResponse;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadList
	 * @summary Returns a list of filtered loads
	 * @request GET:/job-gateway/Load
	 * @deprecated
	 * @secure
	 * @response `200` `ViewLoadResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LoadList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLoadResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadRecalculateUpdate
	 * @summary Recalculate load prices
	 * @request PUT:/job-gateway/Load/{id}/recalculate
	 * @secure
	 * @response `200` `void` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadRecalculateUpdate {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadReportAllDocumentsDetail
	 * @summary Returns all documents on one PDF Report
	 * @request GET:/job-gateway/Load/report/{id}/all-documents
	 * @deprecated
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadReportAllDocumentsDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadReportDetail
	 * @summary Returns mutiple documents on one PDF Report based on selected mode
	 * @request GET:/job-gateway/Load/report/{id}/{mode}
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadReportDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
			mode: GenerateLoadReportModeType;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadReportManifestDetail
	 * @summary Returns load manifest PDF Report
	 * @request GET:/job-gateway/Load/report/{id}/manifest
	 * @secure
	 * @response `200` `File` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadReportManifestDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = File;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadUpdate
	 * @summary Updates a given load
	 * @request PUT:/job-gateway/Load
	 * @secure
	 * @response `200` `DetailedViewLoadResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateLoadRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = DetailedViewLoadResponse;
	}

	/**
	 * No description
	 * @tags Load
	 * @name LoadV2List
	 * @summary Returns a list of filtered loads
	 * @request GET:/job-gateway/Load/v2
	 * @secure
	 * @response `200` `(ViewLoadResponse)[]` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LoadV2List {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLoadResponse[];
	}
}

export namespace LoadType {
	/**
	 * No description
	 * @tags LoadType
	 * @name LoadTypeCreate
	 * @summary Creates a load type
	 * @request POST:/job-gateway/LoadType
	 * @secure
	 * @response `200` `ViewLoadTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadTypeCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateLoadTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLoadTypeResponse;
	}

	/**
	 * No description
	 * @tags LoadType
	 * @name LoadTypeDelete
	 * @summary Deletes a load type
	 * @request DELETE:/job-gateway/LoadType/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadTypeDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags LoadType
	 * @name LoadTypeDetail
	 * @summary Gets a load type by its ID
	 * @request GET:/job-gateway/LoadType/{id}
	 * @secure
	 * @response `200` `ViewLoadTypeResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadTypeDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLoadTypeResponse;
	}

	/**
	 * No description
	 * @tags LoadType
	 * @name LoadTypeList
	 * @summary Get all load types
	 * @request GET:/job-gateway/LoadType
	 * @deprecated
	 * @secure
	 * @response `200` `ViewLoadTypeResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LoadTypeList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLoadTypeResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags LoadType
	 * @name LoadTypeLookupList
	 * @summary Get all load types (lookup)
	 * @request GET:/job-gateway/LoadType/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LoadTypeLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags LoadType
	 * @name LoadTypeUpdate
	 * @summary Updates a load type
	 * @request PUT:/job-gateway/LoadType
	 * @secure
	 * @response `200` `ViewLoadTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LoadTypeUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateLoadTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLoadTypeResponse;
	}
}

export namespace Location {
	/**
	 * No description
	 * @tags Location
	 * @name LocationCreate
	 * @summary Creates a location
	 * @request POST:/job-gateway/Location
	 * @secure
	 * @response `200` `ViewLocationResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LocationCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateLocationRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLocationResponse;
	}

	/**
	 * No description
	 * @tags Location
	 * @name LocationDelete
	 * @summary Deletes a location
	 * @request DELETE:/job-gateway/Location/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LocationDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Location
	 * @name LocationDetail
	 * @summary Gets an location by its ID
	 * @request GET:/job-gateway/Location/{id}
	 * @secure
	 * @response `200` `ViewLocationResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LocationDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLocationResponse;
	}

	/**
	 * No description
	 * @tags Location
	 * @name LocationList
	 * @summary Get all locations
	 * @request GET:/job-gateway/Location
	 * @deprecated
	 * @secure
	 * @response `200` `ViewLocationResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LocationList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLocationResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Location
	 * @name LocationLookupCreate
	 * @summary Get all locations (lookup)
	 * @request POST:/job-gateway/Location/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace LocationLookupCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = LocationLookupCreatePayload;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags Location
	 * @name LocationUpdate
	 * @summary Updates a location
	 * @request PUT:/job-gateway/Location
	 * @secure
	 * @response `200` `ViewLocationResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace LocationUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateLocationRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewLocationResponse;
	}
}

export namespace Qualification {
	/**
	 * No description
	 * @tags Qualification
	 * @name QualificationCreate
	 * @summary Creates a qualification
	 * @request POST:/job-gateway/Qualification
	 * @secure
	 * @response `200` `ViewQualificationResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace QualificationCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateQualificationRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewQualificationResponse;
	}

	/**
	 * No description
	 * @tags Qualification
	 * @name QualificationDelete
	 * @summary Deletes a qualification
	 * @request DELETE:/job-gateway/Qualification/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace QualificationDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Qualification
	 * @name QualificationDetail
	 * @summary Gets an qualification by its ID
	 * @request GET:/job-gateway/Qualification/{id}
	 * @secure
	 * @response `200` `ViewQualificationResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace QualificationDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewQualificationResponse;
	}

	/**
	 * No description
	 * @tags Qualification
	 * @name QualificationDriverDetail
	 * @summary Gets an qualification for a driver
	 * @request GET:/job-gateway/Qualification/driver/{id}
	 * @secure
	 * @response `200` `(ViewQualificationResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace QualificationDriverDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewQualificationResponse[];
	}

	/**
	 * No description
	 * @tags Qualification
	 * @name QualificationList
	 * @summary Get all qualifications
	 * @request GET:/job-gateway/Qualification
	 * @deprecated
	 * @secure
	 * @response `200` `ViewQualificationResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace QualificationList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewQualificationResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Qualification
	 * @name QualificationUpdate
	 * @summary Updates a qualification
	 * @request PUT:/job-gateway/Qualification
	 * @secure
	 * @response `200` `ViewQualificationResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace QualificationUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateQualificationRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewQualificationResponse;
	}
}

export namespace Search {
	/**
	 * No description
	 * @tags Search
	 * @name SearchList
	 * @request GET:/job-gateway/Search
	 * @secure
	 * @response `200` `(ViewSearchResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace SearchList {
		export type RequestParams = {};
		export type RequestQuery = {
			search?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewSearchResponse[];
	}
}

export namespace Subcontractor {
	/**
	 * No description
	 * @tags Subcontractor
	 * @name SubcontractorCreate
	 * @summary Creates a subcontractor
	 * @request POST:/job-gateway/Subcontractor
	 * @secure
	 * @response `200` `ViewSubcontractorResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace SubcontractorCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateSubcontractorRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewSubcontractorResponse;
	}

	/**
	 * No description
	 * @tags Subcontractor
	 * @name SubcontractorDelete
	 * @summary Deletes a subcontractor
	 * @request DELETE:/job-gateway/Subcontractor/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace SubcontractorDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Subcontractor
	 * @name SubcontractorDetail
	 * @summary Gets an subcontractor by its ID
	 * @request GET:/job-gateway/Subcontractor/{id}
	 * @secure
	 * @response `200` `ViewSubcontractorResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace SubcontractorDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewSubcontractorResponse;
	}

	/**
	 * No description
	 * @tags Subcontractor
	 * @name SubcontractorList
	 * @summary Get all subcontractors
	 * @request GET:/job-gateway/Subcontractor
	 * @deprecated
	 * @secure
	 * @response `200` `ViewSubcontractorResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace SubcontractorList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewSubcontractorResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Subcontractor
	 * @name SubcontractorLookupList
	 * @summary Get all subcontractors (lookup)
	 * @request GET:/job-gateway/Subcontractor/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace SubcontractorLookupList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags Subcontractor
	 * @name SubcontractorUpdate
	 * @summary Updates a subcontractor
	 * @request PUT:/job-gateway/Subcontractor
	 * @secure
	 * @response `200` `ViewSubcontractorResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace SubcontractorUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateSubcontractorRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewSubcontractorResponse;
	}
}

export namespace Trailer {
	/**
	 * No description
	 * @tags Trailer
	 * @name TrailerCreate
	 * @summary Creates a trailer
	 * @request POST:/job-gateway/Trailer
	 * @secure
	 * @response `200` `ViewTrailerResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTrailerRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerResponse;
	}

	/**
	 * No description
	 * @tags Trailer
	 * @name TrailerDelete
	 * @summary Deletes a trailer
	 * @request DELETE:/job-gateway/Trailer/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Trailer
	 * @name TrailerDetail
	 * @summary Gets an trailer by its ID
	 * @request GET:/job-gateway/Trailer/{id}
	 * @secure
	 * @response `200` `ViewTrailerResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerResponse;
	}

	/**
	 * No description
	 * @tags Trailer
	 * @name TrailerList
	 * @summary Get all trailers
	 * @request GET:/job-gateway/Trailer
	 * @deprecated
	 * @secure
	 * @response `200` `ViewTrailerResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TrailerList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Trailer
	 * @name TrailerLookupList
	 * @summary Gets all trailers (lookup)
	 * @request GET:/job-gateway/Trailer/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TrailerLookupList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags Trailer
	 * @name TrailerUpdate
	 * @summary Updates a trailer
	 * @request PUT:/job-gateway/Trailer
	 * @secure
	 * @response `200` `ViewTrailerResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateTrailerRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerResponse;
	}
}

export namespace TrailerType {
	/**
	 * No description
	 * @tags TrailerType
	 * @name TrailerTypeCreate
	 * @summary Creates a trailer type
	 * @request POST:/job-gateway/TrailerType
	 * @secure
	 * @response `200` `ViewTrailerTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerTypeCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTrailerTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerTypeResponse;
	}

	/**
	 * No description
	 * @tags TrailerType
	 * @name TrailerTypeDelete
	 * @summary Deletes a trailer type
	 * @request DELETE:/job-gateway/TrailerType/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerTypeDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags TrailerType
	 * @name TrailerTypeDetail
	 * @summary Gets an trailer type by its ID
	 * @request GET:/job-gateway/TrailerType/{id}
	 * @secure
	 * @response `200` `ViewTrailerTypeResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerTypeDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerTypeResponse;
	}

	/**
	 * No description
	 * @tags TrailerType
	 * @name TrailerTypeList
	 * @summary Get all trailer types
	 * @request GET:/job-gateway/TrailerType
	 * @deprecated
	 * @secure
	 * @response `200` `ViewTrailerTypeResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TrailerTypeList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerTypeResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags TrailerType
	 * @name TrailerTypeLookupList
	 * @summary Get all trailer types (lookup)
	 * @request GET:/job-gateway/TrailerType/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TrailerTypeLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags TrailerType
	 * @name TrailerTypeUpdate
	 * @summary Updates a trailer type
	 * @request PUT:/job-gateway/TrailerType
	 * @secure
	 * @response `200` `ViewTrailerTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TrailerTypeUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateTrailerTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTrailerTypeResponse;
	}
}

export namespace Truck {
	/**
	 * No description
	 * @tags Truck
	 * @name TruckCreate
	 * @summary Creates an entity
	 * @request POST:/job-gateway/Truck
	 * @secure
	 * @response `200` `ViewTruckResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TruckCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTruckRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckResponse;
	}

	/**
	 * No description
	 * @tags Truck
	 * @name TruckDelete
	 * @summary Deletes an entity by its ID
	 * @request DELETE:/job-gateway/Truck/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TruckDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags Truck
	 * @name TruckDetail
	 * @summary Gets an entity by its ID
	 * @request GET:/job-gateway/Truck/{id}
	 * @secure
	 * @response `200` `(ViewTruckResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TruckDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckResponse[];
	}

	/**
	 * No description
	 * @tags Truck
	 * @name TruckList
	 * @summary Gets all trucks
	 * @request GET:/job-gateway/Truck
	 * @deprecated
	 * @secure
	 * @response `200` `ViewTruckResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TruckList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags Truck
	 * @name TruckLookupList
	 * @summary Gets all trucks (lookup)
	 * @request GET:/job-gateway/Truck/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TruckLookupList {
		export type RequestParams = {};
		export type RequestQuery = {
			AssignedTo?: string[];
			ConstraintIds?: number[];
			CurrenciesIds?: number[];
			CustomerIds?: number[];
			/** @format date-time */
			DateFrom?: string;
			/** @format date-time */
			DateTo?: string;
			DriverIds?: number[];
			Ids?: number[];
			JobIds?: number[];
			JobTypeIds?: number[];
			LegTypeIds?: number[];
			LoadIds?: number[];
			StatusIds?: number[];
			TrailerTypeIds?: number[];
			TruckTypeIds?: number[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags Truck
	 * @name TruckUpdate
	 * @summary Updates a given entity
	 * @request PUT:/job-gateway/Truck
	 * @secure
	 * @response `200` `ViewTruckResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TruckUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateTruckRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckResponse;
	}
}

export namespace TruckType {
	/**
	 * No description
	 * @tags TruckType
	 * @name TruckTypeCreate
	 * @summary Creates a truck type
	 * @request POST:/job-gateway/TruckType
	 * @secure
	 * @response `200` `ViewTruckTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TruckTypeCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTruckTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckTypeResponse;
	}

	/**
	 * No description
	 * @tags TruckType
	 * @name TruckTypeDelete
	 * @summary Deletes a truck type
	 * @request DELETE:/job-gateway/TruckType/{id}
	 * @secure
	 * @response `200` `void` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TruckTypeDelete {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = undefined;
	}

	/**
	 * No description
	 * @tags TruckType
	 * @name TruckTypeDetail
	 * @summary Gets an truck type by its ID
	 * @request GET:/job-gateway/TruckType/{id}
	 * @secure
	 * @response `200` `ViewTruckTypeResponse` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TruckTypeDetail {
		export type RequestParams = {
			/** @format int32 */
			id: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckTypeResponse;
	}

	/**
	 * No description
	 * @tags TruckType
	 * @name TruckTypeList
	 * @summary Get all truck types
	 * @request GET:/job-gateway/TruckType
	 * @deprecated
	 * @secure
	 * @response `200` `ViewTruckTypeResponseDataSourceResultGeneric` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TruckTypeList {
		export type RequestParams = {};
		export type RequestQuery = {
			request?: DataSourceRequest;
		};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckTypeResponseDataSourceResultGeneric;
	}

	/**
	 * No description
	 * @tags TruckType
	 * @name TruckTypeLookupList
	 * @summary Get all truck types (lookup)
	 * @request GET:/job-gateway/TruckType/lookup
	 * @secure
	 * @response `200` `(ViewBaseResponse)[]` OK
	 * @response `400` `GlobalErrorModel` Bad Request
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 */
	export namespace TruckTypeLookupList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewBaseResponse[];
	}

	/**
	 * No description
	 * @tags TruckType
	 * @name TruckTypeUpdate
	 * @summary Updates a truck type
	 * @request PUT:/job-gateway/TruckType
	 * @secure
	 * @response `200` `ViewTruckTypeResponse` OK
	 * @response `401` `StringStringFAnonymousType0` Unauthorized
	 * @response `404` `GlobalErrorModel` Not Found
	 */
	export namespace TruckTypeUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateTruckTypeRequest;
		export type RequestHeaders = {
			/**
			 * Business Unit you want to access
			 * @default "1"
			 */
			BusinessUnit?: string;
		};
		export type ResponseBody = ViewTruckTypeResponse;
	}
}

export namespace Version {
	/**
	 * No description
	 * @tags Version
	 * @name VersionList
	 * @summary Get version number of the API.
	 * @request GET:/job-gateway/version
	 * @secure
	 * @response `200` `GetVersionResponse` OK
	 * @response `500` `string` Internal Server Error
	 */
	export namespace VersionList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = GetVersionResponse;
	}
}

import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<
	FullRequestParams,
	"body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | undefined> | AxiosRequestConfig | undefined;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || "",
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || params2?.method;

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...(params2?.headers || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		}
		return `${formItem}`;
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = Array.isArray(property)
				? property
				: [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem),
				);
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === "object"
		) {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== "string"
		) {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { "Content-Type": type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @title Celerum TLS Job.API
 * @version v1
 */
export class JobApi<SecurityDataType> extends HttpClient<SecurityDataType> {
	attachment = {
		/**
		 * No description
		 *
		 * @tags Attachment
		 * @name AttachmentCreate
		 * @summary Adds an attachment(s) to an existing entity
		 * @request POST:/job-gateway/Attachment/{entityId}
		 * @secure
		 * @response `200` `(ViewAttachmentResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		attachmentCreate: (
			{ entityId, ...query }: AttachmentCreateParams,
			data: AttachmentCreatePayload,
			params: RequestParams = {},
		) =>
			this.request<
				ViewAttachmentResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Attachment/${entityId}`,
				method: "POST",
				query: query,
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Attachment
		 * @name AttachmentDelete
		 * @summary Deletes an attachment from an existing entity
		 * @request DELETE:/job-gateway/Attachment/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		attachmentDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Attachment/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Attachment
		 * @name AttachmentDetail
		 * @summary Gets an attachment URI
		 * @request GET:/job-gateway/Attachment/{id}
		 * @secure
		 * @response `200` `string` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		attachmentDetail: (id: number, params: RequestParams = {}) =>
			this.request<string, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Attachment/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Attachment
		 * @name AttachmentListDetail
		 * @summary Lists all attachments for an entity
		 * @request GET:/job-gateway/Attachment/{entityId}/list
		 * @secure
		 * @response `200` `(ViewAttachmentResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		attachmentListDetail: (
			{ entityId, ...query }: AttachmentListDetailParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewAttachmentResponse[],
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: `/job-gateway/Attachment/${entityId}/list`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
	bff = {
		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffClosestDriversRecommendationCreate
		 * @request POST:/job-gateway/BFF/closestDriversRecommendation
		 * @secure
		 * @response `200` `(ClosestDriversRecommendation)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffClosestDriversRecommendationCreate: (
			data: GetClosestDriversRecommendationRequest,
			params: RequestParams = {},
		) =>
			this.request<ClosestDriversRecommendation[], StringStringFAnonymousType0>(
				{
					path: "/job-gateway/BFF/closestDriversRecommendation",
					method: "POST",
					body: data,
					secure: true,
					type: ContentType.Json,
					format: "json",
					...params,
				},
			),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffFleetLookupCreate
		 * @request POST:/job-gateway/BFF/fleetLookup
		 * @secure
		 * @response `200` `FleetLookup` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffFleetLookupCreate: (data: FleetLookup, params: RequestParams = {}) =>
			this.request<FleetLookup, StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/fleetLookup",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffGetMultiEditsCreate
		 * @request POST:/job-gateway/BFF/getMultiEdits
		 * @secure
		 * @response `200` `(MultiEdit)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffGetMultiEditsCreate: (
			data: GetMultiEditInput,
			params: RequestParams = {},
		) =>
			this.request<MultiEdit[], StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/getMultiEdits",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffGoodFormDataCreate
		 * @request POST:/job-gateway/BFF/goodFormData
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffGoodFormDataCreate: (
			data: GoodFormDataResponse,
			params: RequestParams = {},
		) =>
			this.request<void, StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/goodFormData",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffGoodFormDataDetail
		 * @request GET:/job-gateway/BFF/goodFormData/{id}
		 * @secure
		 * @response `200` `GoodFormDataResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffGoodFormDataDetail: (id: number, params: RequestParams = {}) =>
			this.request<GoodFormDataResponse, StringStringFAnonymousType0>({
				path: `/job-gateway/BFF/goodFormData/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffJobDetailsDetail
		 * @request GET:/job-gateway/BFF/jobDetails/{id}
		 * @secure
		 * @response `200` `JobDetailsResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffJobDetailsDetail: (id: number, params: RequestParams = {}) =>
			this.request<JobDetailsResponse, StringStringFAnonymousType0>({
				path: `/job-gateway/BFF/jobDetails/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffLegFormDataCreate
		 * @request POST:/job-gateway/BFF/legFormData
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffLegFormDataCreate: (
			data: LegFormDataResponse,
			params: RequestParams = {},
		) =>
			this.request<void, StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/legFormData",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffLegFormDataDetail
		 * @request GET:/job-gateway/BFF/legFormData/{id}
		 * @secure
		 * @response `200` `LegFormDataResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffLegFormDataDetail: (id: number, params: RequestParams = {}) =>
			this.request<LegFormDataResponse, StringStringFAnonymousType0>({
				path: `/job-gateway/BFF/legFormData/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffLegFormDataGoodsDetail
		 * @request GET:/job-gateway/BFF/legFormDataGoods/{id}
		 * @secure
		 * @response `200` `LegFormDataGoodsResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffLegFormDataGoodsDetail: (id: number, params: RequestParams = {}) =>
			this.request<LegFormDataGoodsResponse, StringStringFAnonymousType0>({
				path: `/job-gateway/BFF/legFormDataGoods/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffLegNoteDataCreate
		 * @request POST:/job-gateway/BFF/legNoteData
		 * @secure
		 * @response `200` `(LegNoteData)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffLegNoteDataCreate: (
			query: BffLegNoteDataCreateParams,
			params: RequestParams = {},
		) =>
			this.request<LegNoteData[], StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/legNoteData",
				method: "POST",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffLoadDetailsDetail
		 * @request GET:/job-gateway/BFF/loadDetails/{id}
		 * @secure
		 * @response `200` `LoadDetailsResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffLoadDetailsDetail: (id: number, params: RequestParams = {}) =>
			this.request<LoadDetailsResponse, StringStringFAnonymousType0>({
				path: `/job-gateway/BFF/loadDetails/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffLookupSettingsKeysList
		 * @request GET:/job-gateway/BFF/lookupSettingsKeys
		 * @secure
		 * @response `200` `(string)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffLookupSettingsKeysList: (params: RequestParams = {}) =>
			this.request<string[], StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/lookupSettingsKeys",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffMapSettingsList
		 * @request GET:/job-gateway/BFF/mapSettings
		 * @secure
		 * @response `200` `MapSettings` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffMapSettingsList: (params: RequestParams = {}) =>
			this.request<MapSettings, StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/mapSettings",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffRandomDriversRecommendationCreate
		 * @request POST:/job-gateway/BFF/randomDriversRecommendation
		 * @secure
		 * @response `200` `(RandomDriversRecommendation)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffRandomDriversRecommendationCreate: (params: RequestParams = {}) =>
			this.request<RandomDriversRecommendation[], StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/randomDriversRecommendation",
				method: "POST",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffRecommendationFeedbackCreate
		 * @request POST:/job-gateway/BFF/recommendationFeedback
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffRecommendationFeedbackCreate: (
			data: RecommendationFeedbackInput,
			params: RequestParams = {},
		) =>
			this.request<void, StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/recommendationFeedback",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffRecommendationSettingsList
		 * @request GET:/job-gateway/BFF/recommendationSettings
		 * @secure
		 * @response `200` `(string)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffRecommendationSettingsList: (params: RequestParams = {}) =>
			this.request<string[], StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/recommendationSettings",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags BFF
		 * @name BffSaveMultiEditCreate
		 * @request POST:/job-gateway/BFF/saveMultiEdit
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		bffSaveMultiEditCreate: (
			data: BffSaveMultiEditCreatePayload,
			params: RequestParams = {},
		) =>
			this.request<void, StringStringFAnonymousType0>({
				path: "/job-gateway/BFF/saveMultiEdit",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),
	};
	checklist = {
		/**
		 * No description
		 *
		 * @tags Checklist
		 * @name ChecklistCreate
		 * @summary Creates an entity
		 * @request POST:/job-gateway/Checklist
		 * @secure
		 * @response `200` `ViewChecklistResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		checklistCreate: (
			data: CreateChecklistRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewChecklistResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Checklist",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Checklist
		 * @name ChecklistDelete
		 * @summary Deletes an entity by its ID
		 * @request DELETE:/job-gateway/Checklist/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		checklistDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Checklist/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Checklist
		 * @name ChecklistDetail
		 * @summary Gets an entity by its ID
		 * @request GET:/job-gateway/Checklist/{id}
		 * @secure
		 * @response `200` `ViewChecklistResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		checklistDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewChecklistResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Checklist/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Checklist
		 * @name ChecklistList
		 * @summary Gets all Checklists
		 * @request GET:/job-gateway/Checklist
		 * @deprecated
		 * @secure
		 * @response `200` `ViewChecklistResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		checklistList: (query: ChecklistListParams, params: RequestParams = {}) =>
			this.request<
				ViewChecklistResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Checklist",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Checklist
		 * @name ChecklistLookupList
		 * @summary Gets all Checklists (lookup)
		 * @request GET:/job-gateway/Checklist/lookup
		 * @secure
		 * @response `200` `(ViewChecklistResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		checklistLookupList: (params: RequestParams = {}) =>
			this.request<ViewChecklistResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Checklist/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Checklist
		 * @name ChecklistUpdate
		 * @summary Updates a given entity
		 * @request PUT:/job-gateway/Checklist
		 * @secure
		 * @response `200` `ViewChecklistResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		checklistUpdate: (
			data: UpdateChecklistRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewChecklistResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Checklist",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	constraint = {
		/**
		 * No description
		 *
		 * @tags Constraint
		 * @name ConstraintCreate
		 * @summary Creates a constraint
		 * @request POST:/job-gateway/Constraint
		 * @secure
		 * @response `200` `ViewConstraintResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		constraintCreate: (
			data: CreateConstraintRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewConstraintResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Constraint",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Constraint
		 * @name ConstraintDelete
		 * @summary Deletes constraint by its ID
		 * @request DELETE:/job-gateway/Constraint/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		constraintDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Constraint/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Constraint
		 * @name ConstraintDetail
		 * @summary Gets a constraint by its ID
		 * @request GET:/job-gateway/Constraint/{id}
		 * @secure
		 * @response `200` `(ViewConstraintResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		constraintDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewConstraintResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Constraint/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Constraint
		 * @name ConstraintList
		 * @summary Gets all constraints
		 * @request GET:/job-gateway/Constraint
		 * @deprecated
		 * @secure
		 * @response `200` `ViewConstraintResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		constraintList: (query: ConstraintListParams, params: RequestParams = {}) =>
			this.request<
				ViewConstraintResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Constraint",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Constraint
		 * @name ConstraintLookupList
		 * @summary Gets all constraints (lookup)
		 * @request GET:/job-gateway/Constraint/lookup
		 * @secure
		 * @response `200` `(ViewConstraintResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		constraintLookupList: (params: RequestParams = {}) =>
			this.request<ViewConstraintResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Constraint/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Constraint
		 * @name ConstraintUpdate
		 * @summary Updates a given constraint
		 * @request PUT:/job-gateway/Constraint
		 * @secure
		 * @response `200` `ViewConstraintResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		constraintUpdate: (
			data: UpdateConstraintRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewConstraintResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Constraint",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	currency = {
		/**
		 * No description
		 *
		 * @tags Currency
		 * @name CurrencyBusinessUnitList
		 * @summary Gets currency for current business unit
		 * @request GET:/job-gateway/Currency/businessUnit
		 * @secure
		 * @response `200` `ViewCurrencyResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		currencyBusinessUnitList: (params: RequestParams = {}) =>
			this.request<
				ViewCurrencyResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Currency/businessUnit",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Currency
		 * @name CurrencyCreate
		 * @summary Creates an entity
		 * @request POST:/job-gateway/Currency
		 * @secure
		 * @response `200` `ViewCurrencyResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		currencyCreate: (data: CreateCurrencyRequest, params: RequestParams = {}) =>
			this.request<
				ViewCurrencyResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Currency",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Currency
		 * @name CurrencyDelete
		 * @summary Deletes an entity by its ID
		 * @request DELETE:/job-gateway/Currency/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		currencyDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Currency/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Currency
		 * @name CurrencyDetail
		 * @summary Gets an entity by its ID
		 * @request GET:/job-gateway/Currency/{id}
		 * @secure
		 * @response `200` `ViewCurrencyResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		currencyDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewCurrencyResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Currency/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Currency
		 * @name CurrencyList
		 * @summary Gets all currencies
		 * @request GET:/job-gateway/Currency
		 * @deprecated
		 * @secure
		 * @response `200` `ViewCurrencyResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		currencyList: (query: CurrencyListParams, params: RequestParams = {}) =>
			this.request<
				ViewCurrencyResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Currency",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Currency
		 * @name CurrencyLookupList
		 * @summary Gets all currencies (lookup)
		 * @request GET:/job-gateway/Currency/lookup
		 * @secure
		 * @response `200` `(ViewCurrencyResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		currencyLookupList: (params: RequestParams = {}) =>
			this.request<ViewCurrencyResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Currency/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Currency
		 * @name CurrencyUpdate
		 * @summary Updates a given entity
		 * @request PUT:/job-gateway/Currency
		 * @secure
		 * @response `200` `ViewCurrencyResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		currencyUpdate: (data: UpdateCurrencyRequest, params: RequestParams = {}) =>
			this.request<
				ViewCurrencyResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Currency",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	currencyExchangeRate = {
		/**
		 * No description
		 *
		 * @tags CurrencyExchangeRate
		 * @name CurrencyExchangeRateCreate
		 * @summary Creates an entity
		 * @request POST:/job-gateway/CurrencyExchangeRate
		 * @secure
		 * @response `200` `ViewCurrencyExchangeRateResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		currencyExchangeRateCreate: (
			data: CreateCurrencyExchangeRateRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewCurrencyExchangeRateResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/CurrencyExchangeRate",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CurrencyExchangeRate
		 * @name CurrencyExchangeRateDelete
		 * @summary Deletes an entity by its ID
		 * @request DELETE:/job-gateway/CurrencyExchangeRate/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		currencyExchangeRateDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/CurrencyExchangeRate/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CurrencyExchangeRate
		 * @name CurrencyExchangeRateDetail
		 * @summary Gets an entity by its ID
		 * @request GET:/job-gateway/CurrencyExchangeRate/{id}
		 * @secure
		 * @response `200` `ViewCurrencyExchangeRateResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		currencyExchangeRateDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewCurrencyExchangeRateResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/CurrencyExchangeRate/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CurrencyExchangeRate
		 * @name CurrencyExchangeRateList
		 * @summary Gets all currency exchange rates
		 * @request GET:/job-gateway/CurrencyExchangeRate
		 * @deprecated
		 * @secure
		 * @response `200` `ViewCurrencyExchangeRateResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		currencyExchangeRateList: (
			query: CurrencyExchangeRateListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewCurrencyExchangeRateResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/CurrencyExchangeRate",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CurrencyExchangeRate
		 * @name CurrencyExchangeRateUpdate
		 * @summary Updates a given entity
		 * @request PUT:/job-gateway/CurrencyExchangeRate
		 * @secure
		 * @response `200` `ViewCurrencyExchangeRateResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		currencyExchangeRateUpdate: (
			data: UpdateCurrencyExchangeRateRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewCurrencyExchangeRateResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/CurrencyExchangeRate",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	customer = {
		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerBulkCreate
		 * @summary Creates bulk entities
		 * @request POST:/job-gateway/Customer/bulk
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		customerBulkCreate: (
			data: CustomerBulkCreatePayload,
			params: RequestParams = {},
		) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: "/job-gateway/Customer/bulk",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerCreate
		 * @summary Creates an entity
		 * @request POST:/job-gateway/Customer
		 * @secure
		 * @response `200` `ViewCustomerResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		customerCreate: (data: CreateCustomerRequest, params: RequestParams = {}) =>
			this.request<
				ViewCustomerResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Customer",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerDelete
		 * @summary Deletes an entity by its ID
		 * @request DELETE:/job-gateway/Customer/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		customerDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Customer/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerDetail
		 * @summary Gets an entity by its ID
		 * @request GET:/job-gateway/Customer/{id}
		 * @secure
		 * @response `200` `ViewCustomerResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		customerDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewCustomerResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Customer/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerList
		 * @summary Gets all customers
		 * @request GET:/job-gateway/Customer
		 * @deprecated
		 * @secure
		 * @response `200` `ViewCustomerResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		customerList: (query: CustomerListParams, params: RequestParams = {}) =>
			this.request<
				ViewCustomerResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Customer",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerLookupList
		 * @summary Gets all customers (lookup)
		 * @request GET:/job-gateway/Customer/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		customerLookupList: (
			query: CustomerLookupListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Customer/lookup",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerSyncList
		 * @request GET:/job-gateway/Customer/sync
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		customerSyncList: (params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: "/job-gateway/Customer/sync",
				method: "GET",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Customer
		 * @name CustomerUpdate
		 * @summary Updates a given entity
		 * @request PUT:/job-gateway/Customer
		 * @secure
		 * @response `200` `ViewCustomerResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		customerUpdate: (data: UpdateCustomerRequest, params: RequestParams = {}) =>
			this.request<
				ViewCustomerResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Customer",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	driver = {
		/**
		 * No description
		 *
		 * @tags Driver
		 * @name DriverAssignedLegDetail
		 * @summary Gets last assigned leg to the driver
		 * @request GET:/job-gateway/Driver/{id}/assigned-leg
		 * @secure
		 * @response `200` `ViewDriverLastAssignedLegResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		driverAssignedLegDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewDriverLastAssignedLegResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: `/job-gateway/Driver/${id}/assigned-leg`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Driver
		 * @name DriverCreate
		 * @summary Creates a driver
		 * @request POST:/job-gateway/Driver
		 * @secure
		 * @response `200` `ViewDriverResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		driverCreate: (data: CreateDriverRequest, params: RequestParams = {}) =>
			this.request<
				ViewDriverResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Driver",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Driver
		 * @name DriverDelete
		 * @summary Deletes a driver
		 * @request DELETE:/job-gateway/Driver/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		driverDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Driver/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Driver
		 * @name DriverDetail
		 * @summary Gets an driver by its ID
		 * @request GET:/job-gateway/Driver/{id}
		 * @secure
		 * @response `200` `ViewDriverResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		driverDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewDriverResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: `/job-gateway/Driver/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Driver
		 * @name DriverList
		 * @summary Get all drivers
		 * @request GET:/job-gateway/Driver
		 * @deprecated
		 * @secure
		 * @response `200` `ViewDriverResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		driverList: (query: DriverListParams, params: RequestParams = {}) =>
			this.request<
				ViewDriverResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Driver",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Driver
		 * @name DriverLookupList
		 * @summary Get all drivers (lookup)
		 * @request GET:/job-gateway/Driver/lookup
		 * @secure
		 * @response `200` `(ViewContactBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		driverLookupList: (params: RequestParams = {}) =>
			this.request<
				ViewContactBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Driver/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Driver
		 * @name DriverUpdate
		 * @summary Updates a driver
		 * @request PUT:/job-gateway/Driver
		 * @secure
		 * @response `200` `ViewDriverResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		driverUpdate: (data: UpdateDriverRequest, params: RequestParams = {}) =>
			this.request<
				ViewDriverResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Driver",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	fleet = {
		/**
		 * No description
		 *
		 * @tags Fleet
		 * @name FleetFindList
		 * @summary Find fleet
		 * @request GET:/job-gateway/Fleet/find
		 * @secure
		 * @response `200` `FleetFindResonse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		fleetFindList: (query: FleetFindListParams, params: RequestParams = {}) =>
			this.request<FleetFindResonse, StringStringFAnonymousType0>({
				path: "/job-gateway/Fleet/find",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
	goods = {
		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsCreate
		 * @summary Creates an entity
		 * @request POST:/job-gateway/Goods
		 * @secure
		 * @response `200` `ViewGoodsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		goodsCreate: (data: CreateGoodsRequest, params: RequestParams = {}) =>
			this.request<
				ViewGoodsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Goods",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsDelete
		 * @summary Deletes an entity by its ID
		 * @request DELETE:/job-gateway/Goods/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		goodsDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Goods/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsDetail
		 * @summary Gets an entity by its ID
		 * @request GET:/job-gateway/Goods/{id}
		 * @secure
		 * @response `200` `ViewGoodsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		goodsDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewGoodsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Goods/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsJobDetail
		 * @summary Gets all goods by job ID
		 * @request GET:/job-gateway/Goods/job/{id}
		 * @deprecated
		 * @secure
		 * @response `200` `ViewGoodsResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		goodsJobDetail: (
			{ id, ...query }: GoodsJobDetailParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewGoodsResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Goods/job/${id}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsLoadCreate
		 * @summary Add goods for load
		 * @request POST:/job-gateway/Goods/load/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		goodsLoadCreate: (
			id: number,
			data: CreateGoodsForLoadRequest,
			params: RequestParams = {},
		) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Goods/load/${id}`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsLoadDelete
		 * @summary Deletes an entity from a load by its ID
		 * @request DELETE:/job-gateway/Goods/{id}/load/{loadId}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		goodsLoadDelete: (id: number, loadId: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Goods/${id}/load/${loadId}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsLoadDetail
		 * @summary Gets all goods by load ID
		 * @request GET:/job-gateway/Goods/load/{id}
		 * @deprecated
		 * @secure
		 * @response `200` `ViewGoodsResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		goodsLoadDetail: (
			{ id, ...query }: GoodsLoadDetailParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewGoodsResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Goods/load/${id}`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsLoadList
		 * @summary Gets all goods that can be taken into a load
		 * @request GET:/job-gateway/Goods/load
		 * @deprecated
		 * @secure
		 * @response `200` `ViewGoodsForLoadRequestDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		goodsLoadList: (query: GoodsLoadListParams, params: RequestParams = {}) =>
			this.request<
				ViewGoodsForLoadRequestDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Goods/load",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsLookupDetail
		 * @summary Get all goods for a given jobId (lookup)
		 * @request GET:/job-gateway/Goods/lookup/{jobId}
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		goodsLookupDetail: (jobId: number, params: RequestParams = {}) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Goods/lookup/${jobId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsReportCargoLabelDetail
		 * @summary Returns Cargo label PDF
		 * @request GET:/job-gateway/Goods/report/{goodId}/cargoLabel
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		goodsReportCargoLabelDetail: (goodId: number, params: RequestParams = {}) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Goods/report/${goodId}/cargoLabel`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsReportCmrDetail
		 * @summary Returns CMR PDF
		 * @request GET:/job-gateway/Goods/report/{goodId}/cmr
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		goodsReportCmrDetail: (goodId: number, params: RequestParams = {}) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Goods/report/${goodId}/cmr`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Goods
		 * @name GoodsUpdate
		 * @summary Updates a given entity
		 * @request PUT:/job-gateway/Goods
		 * @secure
		 * @response `200` `ViewGoodsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		goodsUpdate: (data: UpdateGoodsRequest, params: RequestParams = {}) =>
			this.request<
				ViewGoodsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Goods",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	invoice = {
		/**
		 * No description
		 *
		 * @tags Invoice
		 * @name InvoiceList
		 * @summary Returns a list of filtered invoiced
		 * @request GET:/job-gateway/Invoice
		 * @deprecated
		 * @secure
		 * @response `200` `ViewInvoiceResponseDataSourceResultGeneric` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		invoiceList: (query: InvoiceListParams, params: RequestParams = {}) =>
			this.request<
				ViewInvoiceResponseDataSourceResultGeneric,
				StringStringFAnonymousType0
			>({
				path: "/job-gateway/Invoice",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Invoice
		 * @name InvoiceReportInvoiceDetail
		 * @summary Returns invoice PDF Report
		 * @request GET:/job-gateway/Invoice/report/{id}/invoice
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		invoiceReportInvoiceDetail: (id: number, params: RequestParams = {}) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Invoice/report/${id}/invoice`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Invoice
		 * @name InvoiceScheduleCreate
		 * @summary Schedule invoice creation
		 * @request POST:/job-gateway/Invoice/schedule
		 * @secure
		 * @response `200` `(InvoiceGenerationResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		invoiceScheduleCreate: (
			data: CreateInvoiceBulkRequest,
			params: RequestParams = {},
		) =>
			this.request<
				InvoiceGenerationResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Invoice/schedule",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	job = {
		/**
		 * No description
		 *
		 * @tags Job
		 * @name DeleteJob
		 * @summary Deletes a job by its ID
		 * @request DELETE:/job-gateway/Job/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		deleteJob: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Job/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name GetJob
		 * @summary Returns a list of filtered jobs
		 * @request GET:/job-gateway/Job
		 * @deprecated
		 * @secure
		 * @response `200` `ViewJobResponseDataSourceResultGeneric` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		getJob: (query: GetJobParams, params: RequestParams = {}) =>
			this.request<
				ViewJobResponseDataSourceResultGeneric,
				StringStringFAnonymousType0
			>({
				path: "/job-gateway/Job",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name GetJob2
		 * @summary Gets a job by its ID
		 * @request GET:/job-gateway/Job/{id}
		 * @originalName getJob
		 * @duplicate
		 * @secure
		 * @response `200` `DetailedViewJobResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		getJob2: (id: number, params: RequestParams = {}) =>
			this.request<
				DetailedViewJobResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Job/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobChangeAllStatusCreate
		 * @summary Changes multiple jobs status
		 * @request POST:/job-gateway/Job/{ids}/change-all-status/{status}
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobChangeAllStatusCreate: (
			ids: string,
			status: JobStatusType,
			params: RequestParams = {},
		) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${ids}/change-all-status/${status}`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobChangeStatusCancelCreate
		 * @summary Changes job status to Cancelled
		 * @request POST:/job-gateway/Job/{id}/change-status/cancel
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobChangeStatusCancelCreate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${id}/change-status/cancel`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobChangeStatusCreate
		 * @summary Changes job status
		 * @request POST:/job-gateway/Job/{id}/change-status/{status}
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobChangeStatusCreate: (
			id: number,
			status: JobStatusType,
			params: RequestParams = {},
		) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${id}/change-status/${status}`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobChangeStatusPauseCreate
		 * @summary Changes job status to Paused
		 * @request POST:/job-gateway/Job/{id}/change-status/pause
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobChangeStatusPauseCreate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${id}/change-status/pause`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobChangeStatusResumeCreate
		 * @summary Changes job status to Planned
		 * @request POST:/job-gateway/Job/{id}/change-status/resume
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobChangeStatusResumeCreate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${id}/change-status/resume`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobChecklistsCreate
		 * @summary Saves checklists item state
		 * @request POST:/job-gateway/Job/{id}/checklists
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		jobChecklistsCreate: (
			id: number,
			data: JobChecklistsCreatePayload,
			params: RequestParams = {},
		) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${id}/checklists`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobChecklistsDetail
		 * @summary Gets checklists items by job ID
		 * @request GET:/job-gateway/Job/{id}/checklists
		 * @secure
		 * @response `200` `(ViewJobChecklistSettingModel)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		jobChecklistsDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewJobChecklistSettingModel[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Job/${id}/checklists`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobDuplicateCreate
		 * @summary Creates a job
		 * @request POST:/job-gateway/Job/duplicate/{jobId}
		 * @secure
		 * @response `200` `DetailedViewJobResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		jobDuplicateCreate: (
			jobId: number,
			data: CreateJobRequest,
			params: RequestParams = {},
		) =>
			this.request<
				DetailedViewJobResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Job/duplicate/${jobId}`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobForceCompleteCreate
		 * @summary Force changes job status to Completed
		 * @request POST:/job-gateway/Job/{id}/force-complete
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobForceCompleteCreate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${id}/force-complete`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobIsNewUpdate
		 * @summary Update job IsNew flag
		 * @request PUT:/job-gateway/Job/is-new
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		jobIsNewUpdate: (data: UpdateJobIsNewRequest, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0>({
				path: "/job-gateway/Job/is-new",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobPartialUpdate
		 * @summary Partial updates a given job
		 * @request PUT:/job-gateway/Job/partial
		 * @secure
		 * @response `200` `DetailedViewJobResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobPartialUpdate: (
			data: PartialUpdateJobRequest,
			params: RequestParams = {},
		) =>
			this.request<
				DetailedViewJobResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Job/partial",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobRecalculateUpdate
		 * @summary Recalculate job prices
		 * @request PUT:/job-gateway/Job/{id}/recalculate
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobRecalculateUpdate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/${id}/recalculate`,
				method: "PUT",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobReportFileFrontDetail
		 * @summary Returns file front PDF Report
		 * @request GET:/job-gateway/Job/report/{id}/fileFront
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobReportFileFrontDetail: (id: number, params: RequestParams = {}) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Job/report/${id}/fileFront`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name JobV2List
		 * @summary Returns a list of filtered jobs
		 * @request GET:/job-gateway/Job/v2
		 * @secure
		 * @response `200` `(ViewJobResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		jobV2List: (query: JobV2ListParams, params: RequestParams = {}) =>
			this.request<ViewJobResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Job/v2",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name PostJob
		 * @summary Creates a job
		 * @request POST:/job-gateway/Job
		 * @secure
		 * @response `200` `DetailedViewJobResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		postJob: (data: CreateJobRequest, params: RequestParams = {}) =>
			this.request<
				DetailedViewJobResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Job",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Job
		 * @name PutJob
		 * @summary Updates a given job
		 * @request PUT:/job-gateway/Job
		 * @secure
		 * @response `200` `DetailedViewJobResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		putJob: (data: UpdateJobRequest, params: RequestParams = {}) =>
			this.request<
				DetailedViewJobResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Job",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	jobType = {
		/**
		 * No description
		 *
		 * @tags JobType
		 * @name JobTypeCreate
		 * @summary Creates a job type
		 * @request POST:/job-gateway/JobType
		 * @secure
		 * @response `200` `ViewJobTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobTypeCreate: (data: CreateJobTypeRequest, params: RequestParams = {}) =>
			this.request<
				ViewJobTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/JobType",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags JobType
		 * @name JobTypeDelete
		 * @summary Deletes a job type
		 * @request DELETE:/job-gateway/JobType/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobTypeDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/JobType/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags JobType
		 * @name JobTypeDetail
		 * @summary Gets a job type by its ID
		 * @request GET:/job-gateway/JobType/{id}
		 * @secure
		 * @response `200` `ViewJobTypeResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobTypeDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewJobTypeResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/JobType/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags JobType
		 * @name JobTypeList
		 * @summary Get all job types
		 * @request GET:/job-gateway/JobType
		 * @deprecated
		 * @secure
		 * @response `200` `ViewJobTypeResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		jobTypeList: (query: JobTypeListParams, params: RequestParams = {}) =>
			this.request<
				ViewJobTypeResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/JobType",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags JobType
		 * @name JobTypeLookupList
		 * @summary Get all job types (lookup)
		 * @request GET:/job-gateway/JobType/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		jobTypeLookupList: (params: RequestParams = {}) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/JobType/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags JobType
		 * @name JobTypeUpdate
		 * @summary Updates a job type
		 * @request PUT:/job-gateway/JobType
		 * @secure
		 * @response `200` `ViewJobTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		jobTypeUpdate: (data: UpdateJobTypeRequest, params: RequestParams = {}) =>
			this.request<
				ViewJobTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/JobType",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	leg = {
		/**
		 * No description
		 *
		 * @tags Leg
		 * @name DeleteLeg
		 * @summary Deletes a leg by its ID
		 * @request DELETE:/job-gateway/Leg/{legId}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		deleteLeg: (legId: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Leg/${legId}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name GetLeg
		 * @summary Returns a list of filtered legs
		 * @request GET:/job-gateway/Leg
		 * @deprecated
		 * @secure
		 * @response `200` `ViewDisplayLegsResponseDataSourceResultGeneric` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		getLeg: (query: GetLegParams, params: RequestParams = {}) =>
			this.request<
				ViewDisplayLegsResponseDataSourceResultGeneric,
				StringStringFAnonymousType0
			>({
				path: "/job-gateway/Leg",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name GetLeg2
		 * @summary Gets a leg by its ID
		 * @request GET:/job-gateway/Leg/{legId}
		 * @originalName getLeg
		 * @duplicate
		 * @secure
		 * @response `200` `ViewLegsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		getLeg2: (legId: number, params: RequestParams = {}) =>
			this.request<
				ViewLegsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Leg/${legId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegChangeStatusCreate
		 * @summary Changes leg status
		 * @request POST:/job-gateway/Leg/{legId}/change-status
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legChangeStatusCreate: (
			{ legId, ...query }: LegChangeStatusCreateParams,
			params: RequestParams = {},
		) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Leg/${legId}/change-status`,
				method: "POST",
				query: query,
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegForDriverList
		 * @request GET:/job-gateway/Leg/forDriver
		 * @secure
		 * @response `200` `(ViewLegsForDriverResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legForDriverList: (params: RequestParams = {}) =>
			this.request<ViewLegsForDriverResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Leg/forDriver",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegIsNewUpdate
		 * @summary Update leg IsNew flag
		 * @request PUT:/job-gateway/Leg/is-new
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legIsNewUpdate: (
			data: UpdateLegsIsNewRequest,
			params: RequestParams = {},
		) =>
			this.request<void, StringStringFAnonymousType0>({
				path: "/job-gateway/Leg/is-new",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegJobDetail
		 * @summary Gets all legs by job ID
		 * @request GET:/job-gateway/Leg/job/{jobId}
		 * @secure
		 * @response `200` `(ViewDisplayLegsResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legJobDetail: (jobId: number, params: RequestParams = {}) =>
			this.request<
				ViewDisplayLegsResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Leg/job/${jobId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegJobGroupedDetail
		 * @summary Gets alternative view grouped legs by job ID
		 * @request GET:/job-gateway/Leg/job/{jobId}/grouped
		 * @secure
		 * @response `200` `GroupViewLegsResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legJobGroupedDetail: (jobId: number, params: RequestParams = {}) =>
			this.request<GroupViewLegsResponse, StringStringFAnonymousType0>({
				path: `/job-gateway/Leg/job/${jobId}/grouped`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegLoadDetail
		 * @summary Gets all legs by load ID
		 * @request GET:/job-gateway/Leg/load/{loadId}
		 * @secure
		 * @response `200` `(ViewDisplayLegsResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legLoadDetail: (loadId: number, params: RequestParams = {}) =>
			this.request<ViewDisplayLegsResponse[], StringStringFAnonymousType0>({
				path: `/job-gateway/Leg/load/${loadId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegLoadGroupedDetail
		 * @summary Gets alternative view grouped legs by load ID
		 * @request GET:/job-gateway/Leg/load/{loadId}/grouped
		 * @secure
		 * @response `200` `GroupViewLegsResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legLoadGroupedDetail: (loadId: number, params: RequestParams = {}) =>
			this.request<GroupViewLegsResponse, StringStringFAnonymousType0>({
				path: `/job-gateway/Leg/load/${loadId}/grouped`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegReportCollectionNoteDetail
		 * @summary Returns collection note PDF
		 * @request GET:/job-gateway/Leg/report/{legId}/collectionNote/{goodsId}
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		legReportCollectionNoteDetail: (
			legId: number,
			goodsId: number,
			params: RequestParams = {},
		) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Leg/report/${legId}/collectionNote/${goodsId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegReportDeliveryTicketDetail
		 * @summary Returns collection note PDF
		 * @request GET:/job-gateway/Leg/report/{legId}/deliveryTicket/{goodsId}
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		legReportDeliveryTicketDetail: (
			legId: number,
			goodsId: number,
			params: RequestParams = {},
		) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Leg/report/${legId}/deliveryTicket/${goodsId}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegReportSubcontractorOrderDetail
		 * @summary Returns subcontractor order PDF
		 * @request GET:/job-gateway/Leg/report/{legId}/subcontractorOrder
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		legReportSubcontractorOrderDetail: (
			legId: number,
			params: RequestParams = {},
		) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Leg/report/${legId}/subcontractorOrder`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegStatusesList
		 * @summary Retrieves leg statuses
		 * @request GET:/job-gateway/Leg/statuses
		 * @secure
		 * @response `200` `(ViewLegStatusResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legStatusesList: (params: RequestParams = {}) =>
			this.request<ViewLegStatusResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Leg/statuses",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegSupplierInvoiceNumberUpdate
		 * @summary Partial leg update
		 * @request PUT:/job-gateway/Leg/{legId}/supplier-invoice-number
		 * @secure
		 * @response `200` `ViewLegsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		legSupplierInvoiceNumberUpdate: (
			legId: number,
			data: PartialUpdateLegsRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewLegsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Leg/${legId}/supplier-invoice-number`,
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegSupplierInvoiceNumberUpdate2
		 * @summary Updates a given leg with Supplier Invoice Number
		 * @request PUT:/job-gateway/Leg/{legId}/supplier-invoice-number/{supplierInvoiceNumber}/{supplierInvoiceDate}
		 * @deprecated
		 * @originalName legSupplierInvoiceNumberUpdate
		 * @duplicate
		 * @secure
		 * @response `200` `ViewLegsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		legSupplierInvoiceNumberUpdate2: (
			legId: number,
			supplierInvoiceNumber: string,
			supplierInvoiceDate: string,
			params: RequestParams = {},
		) =>
			this.request<
				ViewLegsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path:
					`/job-gateway/Leg/${legId}/supplier-invoice-number/${supplierInvoiceNumber}/${supplierInvoiceDate}`,
				method: "PUT",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegV2Create
		 * @summary Creates an leg v2
		 * @request POST:/job-gateway/Leg/v2
		 * @secure
		 * @response `200` `ViewLegsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legV2Create: (data: CreateLegsRequestV2, params: RequestParams = {}) =>
			this.request<
				ViewLegsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Leg/v2",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegV2List
		 * @summary Returns a list of filtered legs
		 * @request GET:/job-gateway/Leg/v2
		 * @secure
		 * @response `200` `(ViewDisplayLegsResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legV2List: (query: LegV2ListParams, params: RequestParams = {}) =>
			this.request<ViewDisplayLegsResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Leg/v2",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name LegV2Update
		 * @summary Updates a given leg v2
		 * @request PUT:/job-gateway/Leg/v2
		 * @secure
		 * @response `200` `ViewLegsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		legV2Update: (data: UpdateLegsRequestV2, params: RequestParams = {}) =>
			this.request<
				ViewLegsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Leg/v2",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name PostLeg
		 * @summary Creates an leg
		 * @request POST:/job-gateway/Leg
		 * @secure
		 * @response `200` `ViewLegsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		postLeg: (data: CreateLegsRequest, params: RequestParams = {}) =>
			this.request<
				ViewLegsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Leg",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Leg
		 * @name PutLeg
		 * @summary Updates a given leg
		 * @request PUT:/job-gateway/Leg
		 * @secure
		 * @response `200` `ViewLegsResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		putLeg: (data: UpdateLegsRequest, params: RequestParams = {}) =>
			this.request<
				ViewLegsResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Leg",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	legStatus = {
		/**
		 * No description
		 *
		 * @tags LegStatus
		 * @name LegStatusList
		 * @summary Gets all leg statuses
		 * @request GET:/job-gateway/LegStatus
		 * @secure
		 * @response `200` `(ViewLegStatusResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legStatusList: (params: RequestParams = {}) =>
			this.request<
				ViewLegStatusResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/LegStatus",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags LegStatus
		 * @name LegStatusLookupList
		 * @summary Gets all leg statuses (lookup)
		 * @request GET:/job-gateway/LegStatus/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		legStatusLookupList: (params: RequestParams = {}) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/LegStatus/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	load = {
		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadChangeStatusCancelCreate
		 * @summary Changes load status to Cancelled
		 * @request POST:/job-gateway/Load/{id}/change-status/cancel
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadChangeStatusCancelCreate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/${id}/change-status/cancel`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadChangeStatusCreate
		 * @summary Change load status
		 * @request POST:/job-gateway/Load/{id}/change-status/{status}
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadChangeStatusCreate: (
			id: number,
			status: LoadStatusType,
			params: RequestParams = {},
		) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/${id}/change-status/${status}`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadChangeStatusPauseCreate
		 * @summary Changes load status to Paused
		 * @request POST:/job-gateway/Load/{id}/change-status/pause
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadChangeStatusPauseCreate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/${id}/change-status/pause`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadChangeStatusResumeCreate
		 * @summary Changes load status to Planned
		 * @request POST:/job-gateway/Load/{id}/change-status/resume
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadChangeStatusResumeCreate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/${id}/change-status/resume`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadClearCustomsAttachmentsDetail
		 * @summary Gets clear customs attachments by load ID
		 * @request GET:/job-gateway/Load/{id}/clearCustomsAttachments
		 * @secure
		 * @response `200` `(ViewAttachmentResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadClearCustomsAttachmentsDetail: (
			id: number,
			params: RequestParams = {},
		) =>
			this.request<
				ViewAttachmentResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Load/${id}/clearCustomsAttachments`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadCreate
		 * @summary Creates a load
		 * @request POST:/job-gateway/Load
		 * @secure
		 * @response `200` `DetailedViewLoadResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		loadCreate: (data: CreateLoadRequest, params: RequestParams = {}) =>
			this.request<
				DetailedViewLoadResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Load",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadDelete
		 * @summary Deletes a load by its ID
		 * @request DELETE:/job-gateway/Load/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Load/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadDetail
		 * @summary Gets a load by its ID
		 * @request GET:/job-gateway/Load/{id}
		 * @secure
		 * @response `200` `DetailedViewLoadResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				DetailedViewLoadResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Load/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadList
		 * @summary Returns a list of filtered loads
		 * @request GET:/job-gateway/Load
		 * @deprecated
		 * @secure
		 * @response `200` `ViewLoadResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		loadList: (query: LoadListParams, params: RequestParams = {}) =>
			this.request<
				ViewLoadResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Load",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadRecalculateUpdate
		 * @summary Recalculate load prices
		 * @request PUT:/job-gateway/Load/{id}/recalculate
		 * @secure
		 * @response `200` `void` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadRecalculateUpdate: (id: number, params: RequestParams = {}) =>
			this.request<void, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/${id}/recalculate`,
				method: "PUT",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadReportAllDocumentsDetail
		 * @summary Returns all documents on one PDF Report
		 * @request GET:/job-gateway/Load/report/{id}/all-documents
		 * @deprecated
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadReportAllDocumentsDetail: (id: number, params: RequestParams = {}) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/report/${id}/all-documents`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadReportDetail
		 * @summary Returns mutiple documents on one PDF Report based on selected mode
		 * @request GET:/job-gateway/Load/report/{id}/{mode}
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadReportDetail: (
			id: number,
			mode: GenerateLoadReportModeType,
			params: RequestParams = {},
		) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/report/${id}/${mode}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadReportManifestDetail
		 * @summary Returns load manifest PDF Report
		 * @request GET:/job-gateway/Load/report/{id}/manifest
		 * @secure
		 * @response `200` `File` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadReportManifestDetail: (id: number, params: RequestParams = {}) =>
			this.request<File, GlobalErrorModel | StringStringFAnonymousType0>({
				path: `/job-gateway/Load/report/${id}/manifest`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadUpdate
		 * @summary Updates a given load
		 * @request PUT:/job-gateway/Load
		 * @secure
		 * @response `200` `DetailedViewLoadResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadUpdate: (data: UpdateLoadRequest, params: RequestParams = {}) =>
			this.request<
				DetailedViewLoadResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Load",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Load
		 * @name LoadV2List
		 * @summary Returns a list of filtered loads
		 * @request GET:/job-gateway/Load/v2
		 * @secure
		 * @response `200` `(ViewLoadResponse)[]` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		loadV2List: (query: LoadV2ListParams, params: RequestParams = {}) =>
			this.request<ViewLoadResponse[], StringStringFAnonymousType0>({
				path: "/job-gateway/Load/v2",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
	loadType = {
		/**
		 * No description
		 *
		 * @tags LoadType
		 * @name LoadTypeCreate
		 * @summary Creates a load type
		 * @request POST:/job-gateway/LoadType
		 * @secure
		 * @response `200` `ViewLoadTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadTypeCreate: (data: CreateLoadTypeRequest, params: RequestParams = {}) =>
			this.request<
				ViewLoadTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/LoadType",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags LoadType
		 * @name LoadTypeDelete
		 * @summary Deletes a load type
		 * @request DELETE:/job-gateway/LoadType/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadTypeDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/LoadType/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags LoadType
		 * @name LoadTypeDetail
		 * @summary Gets a load type by its ID
		 * @request GET:/job-gateway/LoadType/{id}
		 * @secure
		 * @response `200` `ViewLoadTypeResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadTypeDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewLoadTypeResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/LoadType/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags LoadType
		 * @name LoadTypeList
		 * @summary Get all load types
		 * @request GET:/job-gateway/LoadType
		 * @deprecated
		 * @secure
		 * @response `200` `ViewLoadTypeResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		loadTypeList: (query: LoadTypeListParams, params: RequestParams = {}) =>
			this.request<
				ViewLoadTypeResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/LoadType",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags LoadType
		 * @name LoadTypeLookupList
		 * @summary Get all load types (lookup)
		 * @request GET:/job-gateway/LoadType/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		loadTypeLookupList: (params: RequestParams = {}) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/LoadType/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags LoadType
		 * @name LoadTypeUpdate
		 * @summary Updates a load type
		 * @request PUT:/job-gateway/LoadType
		 * @secure
		 * @response `200` `ViewLoadTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		loadTypeUpdate: (data: UpdateLoadTypeRequest, params: RequestParams = {}) =>
			this.request<
				ViewLoadTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/LoadType",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	location = {
		/**
		 * No description
		 *
		 * @tags Location
		 * @name LocationCreate
		 * @summary Creates a location
		 * @request POST:/job-gateway/Location
		 * @secure
		 * @response `200` `ViewLocationResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		locationCreate: (data: CreateLocationRequest, params: RequestParams = {}) =>
			this.request<
				ViewLocationResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Location",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Location
		 * @name LocationDelete
		 * @summary Deletes a location
		 * @request DELETE:/job-gateway/Location/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		locationDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Location/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Location
		 * @name LocationDetail
		 * @summary Gets an location by its ID
		 * @request GET:/job-gateway/Location/{id}
		 * @secure
		 * @response `200` `ViewLocationResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		locationDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewLocationResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Location/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Location
		 * @name LocationList
		 * @summary Get all locations
		 * @request GET:/job-gateway/Location
		 * @deprecated
		 * @secure
		 * @response `200` `ViewLocationResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		locationList: (query: LocationListParams, params: RequestParams = {}) =>
			this.request<
				ViewLocationResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Location",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Location
		 * @name LocationLookupCreate
		 * @summary Get all locations (lookup)
		 * @request POST:/job-gateway/Location/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		locationLookupCreate: (
			data: LocationLookupCreatePayload,
			params: RequestParams = {},
		) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Location/lookup",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Location
		 * @name LocationUpdate
		 * @summary Updates a location
		 * @request PUT:/job-gateway/Location
		 * @secure
		 * @response `200` `ViewLocationResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		locationUpdate: (data: UpdateLocationRequest, params: RequestParams = {}) =>
			this.request<
				ViewLocationResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Location",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	qualification = {
		/**
		 * No description
		 *
		 * @tags Qualification
		 * @name QualificationCreate
		 * @summary Creates a qualification
		 * @request POST:/job-gateway/Qualification
		 * @secure
		 * @response `200` `ViewQualificationResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		qualificationCreate: (
			data: CreateQualificationRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewQualificationResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Qualification",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Qualification
		 * @name QualificationDelete
		 * @summary Deletes a qualification
		 * @request DELETE:/job-gateway/Qualification/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		qualificationDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Qualification/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Qualification
		 * @name QualificationDetail
		 * @summary Gets an qualification by its ID
		 * @request GET:/job-gateway/Qualification/{id}
		 * @secure
		 * @response `200` `ViewQualificationResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		qualificationDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewQualificationResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Qualification/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Qualification
		 * @name QualificationDriverDetail
		 * @summary Gets an qualification for a driver
		 * @request GET:/job-gateway/Qualification/driver/{id}
		 * @secure
		 * @response `200` `(ViewQualificationResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		qualificationDriverDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewQualificationResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Qualification/driver/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Qualification
		 * @name QualificationList
		 * @summary Get all qualifications
		 * @request GET:/job-gateway/Qualification
		 * @deprecated
		 * @secure
		 * @response `200` `ViewQualificationResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		qualificationList: (
			query: QualificationListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewQualificationResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Qualification",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Qualification
		 * @name QualificationUpdate
		 * @summary Updates a qualification
		 * @request PUT:/job-gateway/Qualification
		 * @secure
		 * @response `200` `ViewQualificationResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		qualificationUpdate: (
			data: UpdateQualificationRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewQualificationResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Qualification",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	search = {
		/**
		 * No description
		 *
		 * @tags Search
		 * @name SearchList
		 * @request GET:/job-gateway/Search
		 * @secure
		 * @response `200` `(ViewSearchResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		searchList: (query: SearchListParams, params: RequestParams = {}) =>
			this.request<
				ViewSearchResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Search",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),
	};
	subcontractor = {
		/**
		 * No description
		 *
		 * @tags Subcontractor
		 * @name SubcontractorCreate
		 * @summary Creates a subcontractor
		 * @request POST:/job-gateway/Subcontractor
		 * @secure
		 * @response `200` `ViewSubcontractorResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		subcontractorCreate: (
			data: CreateSubcontractorRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewSubcontractorResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Subcontractor",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Subcontractor
		 * @name SubcontractorDelete
		 * @summary Deletes a subcontractor
		 * @request DELETE:/job-gateway/Subcontractor/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		subcontractorDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Subcontractor/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Subcontractor
		 * @name SubcontractorDetail
		 * @summary Gets an subcontractor by its ID
		 * @request GET:/job-gateway/Subcontractor/{id}
		 * @secure
		 * @response `200` `ViewSubcontractorResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		subcontractorDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewSubcontractorResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Subcontractor/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Subcontractor
		 * @name SubcontractorList
		 * @summary Get all subcontractors
		 * @request GET:/job-gateway/Subcontractor
		 * @deprecated
		 * @secure
		 * @response `200` `ViewSubcontractorResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		subcontractorList: (
			query: SubcontractorListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewSubcontractorResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Subcontractor",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Subcontractor
		 * @name SubcontractorLookupList
		 * @summary Get all subcontractors (lookup)
		 * @request GET:/job-gateway/Subcontractor/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		subcontractorLookupList: (
			query: SubcontractorLookupListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Subcontractor/lookup",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Subcontractor
		 * @name SubcontractorUpdate
		 * @summary Updates a subcontractor
		 * @request PUT:/job-gateway/Subcontractor
		 * @secure
		 * @response `200` `ViewSubcontractorResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		subcontractorUpdate: (
			data: UpdateSubcontractorRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewSubcontractorResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Subcontractor",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	trailer = {
		/**
		 * No description
		 *
		 * @tags Trailer
		 * @name TrailerCreate
		 * @summary Creates a trailer
		 * @request POST:/job-gateway/Trailer
		 * @secure
		 * @response `200` `ViewTrailerResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerCreate: (data: CreateTrailerRequest, params: RequestParams = {}) =>
			this.request<
				ViewTrailerResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Trailer",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Trailer
		 * @name TrailerDelete
		 * @summary Deletes a trailer
		 * @request DELETE:/job-gateway/Trailer/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Trailer/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Trailer
		 * @name TrailerDetail
		 * @summary Gets an trailer by its ID
		 * @request GET:/job-gateway/Trailer/{id}
		 * @secure
		 * @response `200` `ViewTrailerResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewTrailerResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Trailer/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Trailer
		 * @name TrailerList
		 * @summary Get all trailers
		 * @request GET:/job-gateway/Trailer
		 * @deprecated
		 * @secure
		 * @response `200` `ViewTrailerResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		trailerList: (query: TrailerListParams, params: RequestParams = {}) =>
			this.request<
				ViewTrailerResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Trailer",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Trailer
		 * @name TrailerLookupList
		 * @summary Gets all trailers (lookup)
		 * @request GET:/job-gateway/Trailer/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		trailerLookupList: (
			query: TrailerLookupListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Trailer/lookup",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Trailer
		 * @name TrailerUpdate
		 * @summary Updates a trailer
		 * @request PUT:/job-gateway/Trailer
		 * @secure
		 * @response `200` `ViewTrailerResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerUpdate: (data: UpdateTrailerRequest, params: RequestParams = {}) =>
			this.request<
				ViewTrailerResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/Trailer",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	trailerType = {
		/**
		 * No description
		 *
		 * @tags TrailerType
		 * @name TrailerTypeCreate
		 * @summary Creates a trailer type
		 * @request POST:/job-gateway/TrailerType
		 * @secure
		 * @response `200` `ViewTrailerTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerTypeCreate: (
			data: CreateTrailerTypeRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewTrailerTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/TrailerType",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TrailerType
		 * @name TrailerTypeDelete
		 * @summary Deletes a trailer type
		 * @request DELETE:/job-gateway/TrailerType/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerTypeDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/TrailerType/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TrailerType
		 * @name TrailerTypeDetail
		 * @summary Gets an trailer type by its ID
		 * @request GET:/job-gateway/TrailerType/{id}
		 * @secure
		 * @response `200` `ViewTrailerTypeResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerTypeDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewTrailerTypeResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/TrailerType/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TrailerType
		 * @name TrailerTypeList
		 * @summary Get all trailer types
		 * @request GET:/job-gateway/TrailerType
		 * @deprecated
		 * @secure
		 * @response `200` `ViewTrailerTypeResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		trailerTypeList: (
			query: TrailerTypeListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewTrailerTypeResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/TrailerType",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TrailerType
		 * @name TrailerTypeLookupList
		 * @summary Get all trailer types (lookup)
		 * @request GET:/job-gateway/TrailerType/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		trailerTypeLookupList: (params: RequestParams = {}) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/TrailerType/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TrailerType
		 * @name TrailerTypeUpdate
		 * @summary Updates a trailer type
		 * @request PUT:/job-gateway/TrailerType
		 * @secure
		 * @response `200` `ViewTrailerTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		trailerTypeUpdate: (
			data: UpdateTrailerTypeRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewTrailerTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/TrailerType",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	truck = {
		/**
		 * No description
		 *
		 * @tags Truck
		 * @name TruckCreate
		 * @summary Creates an entity
		 * @request POST:/job-gateway/Truck
		 * @secure
		 * @response `200` `ViewTruckResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		truckCreate: (data: CreateTruckRequest, params: RequestParams = {}) =>
			this.request<
				ViewTruckResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Truck",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Truck
		 * @name TruckDelete
		 * @summary Deletes an entity by its ID
		 * @request DELETE:/job-gateway/Truck/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		truckDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/Truck/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Truck
		 * @name TruckDetail
		 * @summary Gets an entity by its ID
		 * @request GET:/job-gateway/Truck/{id}
		 * @secure
		 * @response `200` `(ViewTruckResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		truckDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewTruckResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/Truck/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Truck
		 * @name TruckList
		 * @summary Gets all trucks
		 * @request GET:/job-gateway/Truck
		 * @deprecated
		 * @secure
		 * @response `200` `ViewTruckResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		truckList: (query: TruckListParams, params: RequestParams = {}) =>
			this.request<
				ViewTruckResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Truck",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Truck
		 * @name TruckLookupList
		 * @summary Gets all trucks (lookup)
		 * @request GET:/job-gateway/Truck/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		truckLookupList: (
			query: TruckLookupListParams,
			params: RequestParams = {},
		) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Truck/lookup",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Truck
		 * @name TruckUpdate
		 * @summary Updates a given entity
		 * @request PUT:/job-gateway/Truck
		 * @secure
		 * @response `200` `ViewTruckResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		truckUpdate: (data: UpdateTruckRequest, params: RequestParams = {}) =>
			this.request<
				ViewTruckResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/Truck",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	truckType = {
		/**
		 * No description
		 *
		 * @tags TruckType
		 * @name TruckTypeCreate
		 * @summary Creates a truck type
		 * @request POST:/job-gateway/TruckType
		 * @secure
		 * @response `200` `ViewTruckTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		truckTypeCreate: (
			data: CreateTruckTypeRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewTruckTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/TruckType",
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TruckType
		 * @name TruckTypeDelete
		 * @summary Deletes a truck type
		 * @request DELETE:/job-gateway/TruckType/{id}
		 * @secure
		 * @response `200` `void` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		truckTypeDelete: (id: number, params: RequestParams = {}) =>
			this.request<void, StringStringFAnonymousType0 | GlobalErrorModel>({
				path: `/job-gateway/TruckType/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TruckType
		 * @name TruckTypeDetail
		 * @summary Gets an truck type by its ID
		 * @request GET:/job-gateway/TruckType/{id}
		 * @secure
		 * @response `200` `ViewTruckTypeResponse` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		truckTypeDetail: (id: number, params: RequestParams = {}) =>
			this.request<
				ViewTruckTypeResponse,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: `/job-gateway/TruckType/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TruckType
		 * @name TruckTypeList
		 * @summary Get all truck types
		 * @request GET:/job-gateway/TruckType
		 * @deprecated
		 * @secure
		 * @response `200` `ViewTruckTypeResponseDataSourceResultGeneric` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		truckTypeList: (query: TruckTypeListParams, params: RequestParams = {}) =>
			this.request<
				ViewTruckTypeResponseDataSourceResultGeneric,
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/TruckType",
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TruckType
		 * @name TruckTypeLookupList
		 * @summary Get all truck types (lookup)
		 * @request GET:/job-gateway/TruckType/lookup
		 * @secure
		 * @response `200` `(ViewBaseResponse)[]` OK
		 * @response `400` `GlobalErrorModel` Bad Request
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 */
		truckTypeLookupList: (params: RequestParams = {}) =>
			this.request<
				ViewBaseResponse[],
				GlobalErrorModel | StringStringFAnonymousType0
			>({
				path: "/job-gateway/TruckType/lookup",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags TruckType
		 * @name TruckTypeUpdate
		 * @summary Updates a truck type
		 * @request PUT:/job-gateway/TruckType
		 * @secure
		 * @response `200` `ViewTruckTypeResponse` OK
		 * @response `401` `StringStringFAnonymousType0` Unauthorized
		 * @response `404` `GlobalErrorModel` Not Found
		 */
		truckTypeUpdate: (
			data: UpdateTruckTypeRequest,
			params: RequestParams = {},
		) =>
			this.request<
				ViewTruckTypeResponse,
				StringStringFAnonymousType0 | GlobalErrorModel
			>({
				path: "/job-gateway/TruckType",
				method: "PUT",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	version = {
		/**
		 * No description
		 *
		 * @tags Version
		 * @name VersionList
		 * @summary Get version number of the API.
		 * @request GET:/job-gateway/version
		 * @secure
		 * @response `200` `GetVersionResponse` OK
		 * @response `500` `string` Internal Server Error
		 */
		versionList: (params: RequestParams = {}) =>
			this.request<GetVersionResponse, string>({
				path: "/job-gateway/version",
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
}
